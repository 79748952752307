import { useEffect, useState } from "react";
import { Accordion, Button, Container} from "react-bootstrap";
import { FaCalendarAlt, FaTruck } from "react-icons/fa";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import { httpApiGetText } from "../../../lib";
import * as env from '../../../env';
import './style.css';
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import { exportToExcel } from "../../../util";
import DataTable from "react-data-table-component";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { useDispatch, useSelector } from "react-redux";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import Multiselect from "multiselect-react-dropdown";

const  Resumen = {
    Diferencia_valor_absoluto     :0,
    Diferencia_valor_contable     :0,
    Inventario_fisico_valorizado  :0,
    Inventario_sistema_valorizado :0,
    Items_Cuadrados               :0,
    Porc_cuadratura_items         :0,
    Porc_descuadre                :0,
    Total_items                   :0, 
}

const cntss: {}[] = [] ;

let columns = [
    { name: "Centro", selector: (row:any) => row.Centro, sortable: true},
    { name: "Almacén", selector: (row:any) => row.Almacen, sortable: true},
    { name: "Total ítems", selector: (row:any) => row.Total_items,  format: (row:any) => row.Total_items.toLocaleString(), sortable: true, right:true, width:"150px"},
    { name: "Ítem Cuadrados", selector: (row:any) => row.Items_Cuadrados,  format: (row:any) => row.Items_Cuadrados.toLocaleString(), sortable: true, right:true, width:"150px"},
    { name: "Inv. Físico Valorizado", selector: (row:any) => row.Inventario_fisico_valorizado, format: (row:any) => row.Inventario_fisico_valorizado.toLocaleString(), right:true, sortable: true, width:"200px"}, 
    { name: "Inv. Sistema Valorizado", selector: (row:any) => row.Inventario_sistema_valorizado, format: (row:any) => row.Inventario_sistema_valorizado.toLocaleString(),  sortable: true, right:true, width:"200px"},
    { name: "Dif. Valor Contable", selector: (row:any) => row.Diferencia_valor_contable, format: (row:any) => row.Diferencia_valor_contable.toLocaleString(),  sortable: true, right:true, width:"150px"},
    { name: "Dif. Valor Absoluto", selector: (row:any) => row.Diferencia_valor_absoluto, format: (row:any) => row.Diferencia_valor_absoluto.toLocaleString(),  right:true, sortable: true, width:"150px"},
    { name: "% Descuadre", selector: (row:any) => row.Porc_descuadre, format: (row:any) => row.Porc_descuadre.toLocaleString(), sortable: true, right:true, width:"200px"},
    { name: "% Descuadre Valorizado", selector: (row:any) => row.Porc_descuadre_valorizado, format: (row:any) => row.Porc_descuadre_valorizado.toLocaleString(),  sortable: true, right:true, width:"200px"},
    { name: "% Cuadratura Ítems", selector: (row:any) => row.Porc_cuadratura_items, format: (row:any) => row.Porc_cuadratura_items.toLocaleString(),  right:true, sortable: true, width:"200px"},
    { name: "% Cuadratura Valorizada", selector: (row:any) => row.Porc_cuadratura_valorizado, format: (row:any) => row.Porc_cuadratura_valorizado.toLocaleString(), sortable: true, right:true, width:"250px"},
    { name: "F. Bajada", selector: (row:any) => row.FEC_BAJADA, format: (row:any) => row.FEC_BAJADA,  sortable: true, right:true, width:"200px"},
    { name: "F. Publicación", selector: (row:any) => row.FECHA_PUBLICACION, format: (row:any) => row.FECHA_PUBLICACION.toLocaleString(),  right:true, sortable: true, width:"200px"},  
    { name: "Hora", selector: (row:any) => row.Hr, sortable: true, grow: 2},      
];

const pagOptions = {
    rowsPerPageText: "Filas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos"
};  


const InformeSaldosPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [almacenesFiltrados, setAlmacenesFiltrados] = useState([]);
    const [almacenesSelected, setAlmacenesSelected] = useState([]);    
    let [centroSelected, setCentroSelected] = useState([]); 
    let [centros, setCentros] = useState(cntss);    
    const [records, setRecords] = useState([]);    
    const [cpRecords, setCpRecords] = useState([]);     
    const [sHCarga, setSHCarga] = useState(false);
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "", Centro: "", Almacen: ""});
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);   
    const [msgRespuestInv, setMsgRespuestInv] = useState(""); 
    const [resumen, setResumen] = useState(Resumen);
    const [btnToExcel, setBtnToExce] = useState(true); 
    const dispatch = useDispatch();   
    
    let [cnts, setCnts] = useState("");
    let [almns, setAlmns] = useState("");

    const hndlrBtnBuscar = async () =>{

        let dts: string = "";
        almacenesSelected.map((dato: any)=>{
            dts = `${dato.cat};${dato.key.split(' - ')[0].trim()}-${dts}`;
        });   

        let lcnts = "";
        let lalmns= "";

        centroSelected.map((el: any) => lcnts+= el.cat + "|");
        almacenesSelected.map((el: any) => lalmns+= el.key.split(" - ")[0] + "|");
        cnts = lcnts.substring(0, lcnts.length-1);
        setCnts(cnts);
        almns = lalmns.substring(0, lalmns.length-1);  
        setAlmns(almns);

        setSHCarga(true);
        
        const recurso = `${env.REACT_APP_API_URL}/api/ReporteInventarioGlobalProd?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centros=${cnts}&Almacenes=${almns}`;       
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        let arrDta = await JSON.parse(response.message);       
        setSHCarga(false);        
        if (response.code >= 400){
            arrDta = await JSON.parse(response.message);  
            setMsgRespuestInv(arrDta.Message);
            setMsgDlgShowApiResponse(true);
        }
        else{
            setResumen(arrDta.Resumen);
            const auxDta:any = [];
            if (arrDta.Detalle.length > 0){  
                arrDta.Detalle.map((obj: any, index: number)=>{
                    const newObj = {
                        ...obj,
                    };
                    auxDta.push(newObj);  
                }); 
                //********Pasa el objeto a arreglo para recorrido en table
                setRecords(auxDta);  
                setCpRecords(auxDta);         
            }else{
                setRecords([]); 
            }    
            setBtnToExce(!(arrDta.Detalle.length  > 0));         
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                        
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);      
    }

    const onSelectedOptionCenter = (e: any) =>{

        centroSelected = e;
        setCentroSelected([...centroSelected]);

        if (e.length > 0){
            const almacenes: any = [];
            e.map((dato: any)=>{
                const alma = emp.almacenes.filter( (alm: string)=> alm.includes(dato.cat) );
                //organiza la estructura de almacens así: {cat: id, key: label} para mostrar en el multiselect
                alma.map((dt: string) => {
                    const cnt = dato.cat;
                    const almcn = `${dt.split(' - ')[1]} - ${dt.split(' - ')[2]}`; 
                    almacenes.push({cat : cnt, key : almcn});
                });
            });
            setAlmacenesFiltrados(almacenes);    
        }else{
            setAlmacenesFiltrados([]);            
        }

    }

    const onRemoveOptionCenter = (e: any, centRmv: any) =>{
        
        const almcremv = almacenesSelected.filter((dato: any) => dato.cat !== centRmv.cat);
        setAlmacenesSelected(almcremv);
        if (almcremv.length < 1){
            setAlmacenesFiltrados([]);
        }

        if (e.length > 0){
            const almacenes: any = [];
            e.map((dato: any)=>{
                const alma = emp.almacenes.filter( (alm: string)=> alm.includes(dato.cat) );
                //organiza la estructura de almacens así: {cat: id, key: label} paramostrar en el multiselect
                alma.map((dt: string) => {
                    const cnt = dato.cat;
                    const almcn = `${dt.split(' - ')[1]} - ${dt.split(' - ')[2]}`; 
                    almacenes.push({cat : cnt, key : almcn});
                });
            });

            setAlmacenesFiltrados(almacenes);    
        }else{
            setAlmacenesFiltrados([]);            
        }
    }
    
    const onSelectedOptionAlmacen = (e: any) =>{

        setAlmacenesSelected(e);

        //hndlrBtnBuscar();

    }

    const onRemoveOptionAlmacen = (e: any) =>{
        
        hndlrBtnBuscar();
    }

    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.Centro && dato.Centro.includes(evnt.target.value.toUpperCase()))
                    || (dato.Almacen && dato.Almacen.includes(evnt.target.value.toUpperCase())) 
                    || (dato.Total_items && dato.Total_items.toString().includes(evnt.target.value.toUpperCase()))  
                    || (dato.Items_Cuadrados && dato.Items_Cuadrados.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Inventario_fisico_valorizado && dato.Inventario_fisico_valorizado.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Inventario_sistema_valorizado && dato.Inventario_sistema_valorizado.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Diferencia_valor_contable && dato.Diferencia_valor_contable.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Diferencia_valor_absoluto && dato.Diferencia_valor_absoluto.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Porc_descuadre && dato.Porc_descuadre.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Porc_descuadre_valorizado && dato.Porc_descuadre_valorizado.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Porc_cuadratura_items && dato.Porc_cuadratura_items.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Hr && dato.Hr.toString().includes(evnt.target.value.toUpperCase()));                        
            }); 
            setRecords(reg); 
        }
    }  

    const DatosInventarioConsole = async () => {

        setSHCarga(true);

        const recurso = `${env.REACT_APP_API_URL}/api/ReporteInventarioGlobalConsola?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centros=${cnts}&Almacenes=${almns}`;       
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message); 
        setSHCarga(false);   
        if (response.code >= 400){
            setMsgRespuestInv("No se pudo recuperar las información solicitada.");
            setMsgDlgShowApiResponse(true);
        }
        else{
            setResumen(arrDta.Resumen);
            const auxDta:any = [];
            if (arrDta.Detalle.length > 0){  
                arrDta.Detalle.map((obj: any, index: number)=>{
                    const newObj = {
                        ...obj,
                    };
                    auxDta.push(newObj);  
                }); 
                //********Pasa el objeto a arreglo para recorrido en table
                setRecords(auxDta);  
                setCpRecords(auxDta);         
            }else{
                setRecords([]); 
            }    
            setBtnToExce(!(arrDta.Detalle.length  > 0));         
        }
    }      
    
    useEffect(()=>{

        const preFiltros = async () =>{

            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            const centross: any = [];
            await emp.centros.map((dt: string) => {
                const cnt = dt.split(' - ')[0];
                const nmCnt = `${cnt} - ${dt.split(' - ')[1]}`; 
                centross.push({cat : cnt, key : nmCnt});
            });  
            setCentros(centross);            
        }

        preFiltros();   

    }, []);       

    return(
        <>
            <Container fluid>
            <BarraMenuHz/>  
                <div >
                    <div className="text-primary ms-3 mt-3">
                            Home / Consulta / Informe de Saldos
                    </div>
                    <div className="h4 mt-4 mb-4 ms-3">INFORME DE SALDOS</div>
                </div> 
                <Container fluid>               
                    <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">PARÁMETROS INFORMES</div></Accordion.Header>
                            <Accordion.Body>
                                <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                                <div className="align-middle">
                                    <FaCalendarAlt className="h5" /> 
                                    <label className="ms-2 h4 ">Periodo</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" />
                                <div  className="d-flex flex-column col-md-12 col-lg-6">
                                    <label className="m-2 ">Mes/Año</label>
                                    <input type="month" id="periodo" size={8}  className="ms-2"
                                        onChange={hndlrPeriodo}
                                        value={`${formData.Anno}-${formData.Mes}`} 
                                        min="1990-01" max="9999-12"
                                    />
                                </div>
                                <div className="align-middle mt-4">
                                    <FaTruck className="h5" /> 
                                    <label className="ms-2 h4 ">Centro y Almacenes</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" /> 

                                <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">* Seleccionar Centro de distribución</label>    
                                    <div>
                                        <Multiselect className="" aria-label="Default select example" 
                                            onSelect={(e: any)=>onSelectedOptionCenter(e)}
                                            onRemove={(e: any, remcnt: any)=>onRemoveOptionCenter(e, remcnt)}
                                            options={centros}
                                            displayValue="key"
                                            placeholder="Centros de distribución"
                                        />
                                    </div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">* Seleccionar Almacen </label>    
                                    <div>
                                        <Multiselect className="" aria-label="Default select example" 
                                            onSelect={(e: any)=>onSelectedOptionAlmacen(e)}  
                                            onRemove={onRemoveOptionAlmacen}                                         
                                            options={almacenesFiltrados}
                                            displayValue="key"
                                            placeholder="Almacen(es)"
                                            selectedValues={almacenesSelected}
                                        />
                                    </div>
                                </Container>
                            </div>

                                <div className="text-end">
                                    <Button type="button" disabled={!((almacenesSelected.length > 0) && (formData.Anno !== ""))} 
                                    style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" onClick={hndlrBtnBuscar}
                                    >
                                        Filtrar
                                    </Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>         

                    <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">INVENTARIO</div></Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <div className="row mt-4 mb-4">
                                        <div className=" col-md-12 col-lg-6 " > 
                                            <Button type="button" className="border-0" style={{backgroundColor:"#16D39A"}}
                                                disabled={btnToExcel}
                                                onClick={async ()=> exportToExcel("tablaInformeSaldos", records)}>
                                                Exportar
                                            </Button>
                                        </div>   
                                        <div className=" col-md-12 col-lg-6 " > 
                                            <Button type="button" className="border-0" style={{backgroundColor:"#16D39A"}}
                                                disabled={btnToExcel}
                                                onClick={async ()=> DatosInventarioConsole()}>
                                                Cargar Datos Inventario Consola
                                            </Button>
                                        </div>                                           
                                        <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                            <label className="m-1">Filtro</label>
                                            <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                        </div>                                                      
                                    </div>            
                                    <DataTable
                                        columns={columns}
                                        data={records}
                                        selectableRowsHighlight
                                        fixedHeader
                                        pagination
                                        highlightOnHover
                                        fixedHeaderScrollHeight="600px"
                                        paginationComponentOptions={pagOptions}
                                    />     
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  

                    <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">RESUMEN</div></Accordion.Header>
                            <Accordion.Body  className=" p-2 mb-2 ">
                                <div className="row">
                                    <div  className="mt-2 mb-2 col-md-12 col-lg-3" >
                                        <label className="form-label">Total Items</label>    
                                        <div><input type="text" name="totItems" id="totItems" value={resumen.Total_items.toLocaleString()} readOnly className=" form-control text-end" /></div>
                                    </div>
                                    <div  className="mt-2 mb-2  col-md-12 col-lg-3" >
                                        <label className="form-label">Items Cuadrados</label>    
                                        <div ><input type="text" name="ifv" id="ifv" value={resumen.Items_Cuadrados.toLocaleString()} readOnly   className=" form-control text-end" /></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-3" >
                                        <label className="form-label">Inv. Físico valorizado ($)</label>    
                                        <div ><input type="text" name="valneg" id="valneg" value={resumen.Inventario_fisico_valorizado.toLocaleString()} readOnly   className=" form-control text-end" /></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-3" >
                                        <label className="form-label">Inv. Sistema valorizado ($)</label>    
                                        <div ><input type="text" name="dvc" id="dvc" value={resumen.Inventario_sistema_valorizado.toLocaleString()} readOnly  className=" form-control text-end" /></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-3" >
                                        <label className="form-label">Diferencia valor contable ($)</label>    
                                        <div ><input type="text" name="div" id="div" value={resumen.Diferencia_valor_contable.toLocaleString()} readOnly className=" form-control text-end"  /></div>
                                    </div>
                                    <div  className="mt-2 mb-2    col-md-12 col-lg-3" >
                                        <label className="form-label">Diferencia valor absoluto ($)</label>    
                                        <div ><input type="text" name="itcuad" id="itcuad"  value={resumen.Diferencia_valor_absoluto.toLocaleString()} readOnly className=" form-control text-end" /></div>
                                    </div>
                                    <div  className="mt-2 mb-2    col-md-12 col-lg-3" >
                                        <label className="form-label">Porcentaje Descuadre (%)</label>    
                                        <div ><input type="text" name="isv" id="isv"  value={resumen.Porc_descuadre.toLocaleString()} readOnly   className=" form-control text-end" /></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-3" >
                                        <label className="form-label">Porcentaje Cuadratura Items (%)</label>    
                                        <div ><input type="text" name="valpos" id="valpos"  value={resumen.Porc_cuadratura_items.toLocaleString()} readOnly   className=" form-control text-end" /></div>
                                    </div> 
                                </div>                                                   
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> 
                </Container>                     
            </Container>
            {/*********** cuadro de dialogo para msj de api **********/}
            {msgDlgShowApiResponse && <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Informe de saldos`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={()=>setMsgDlgShowApiResponse(false)}
                HanlerdClickNok={null}
            />}  
            {sHCarga && <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            /> }              
        </>
    );
}

export default InformeSaldosPage;
