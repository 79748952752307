import { useEffect, useState } from "react";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaCalendarAlt, FaCheck, FaCircle, FaDotCircle, FaEye, FaTruck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import BarraMenuHz from "../../../components/BarraMenoHz";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import * as env from '../../../env';
import { httpApiGetText } from "../../../lib";
import { SetDataTable, SetEntornoEmp } from "../../../redux/store/Actions";
import { exportToExcel } from "../../../util";
import MsgDialogDetalleReporteInv from "./MsgDialogDetalleReporteInv";
import MsgDialogReconteoRepoInv from "./MsgDialogReconteoRepoInv";
import './style.css';
  
const ReporteInventarioPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    let [records, setRecords] = useState([]);
    const [cpRecords, setCpRecords] = useState([]);    
    let dt: any = useSelector((state: any) => state.datatable);  
    const [msgDlgShow, setMsgDlgShow] = useState(false);
    const [msgDlgShowReconteo, setMsgDlgShowReconteo] = useState(false);    
    let [sinoDifRec, setSinoDifRec] = useState({dif: "0", rec: "1"});
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true);
    const [sHCarga, setSHCarga] = useState(false);
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    const [msgRespuestInv, setMsgRespuestInv] = useState(""); 
    const [btnToExcel, setBtnToExce] = useState(true);
    const dispatch = useDispatch();
    const [element, setElement] = useState({});
    const [resumen, setResumen] = useState({
        Pais: "",
        Anno: "",
        Mes: "",
        Centro: "",
        Almacen: "",
        TotalItems: 0,
        ItemsGuardados: 0,
        InvSistemaValorizado: 0,
        InvFisicoValorizado: 0,
        ValoresNegativos: 0,
        ValoresPositivos: 0,
        DiferenciaValorContable: 0,
        DiferenciaValorAbsoluto: 0,
        PorcDescuadreInvValorizado: 0,
        PorcCuadraturaItems: 0
    });    
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: ""});      

    let columns = [
        { name: "Código", selector: (row:any) => row.Codigo, sortable: true},
        { name: "E", selector: (row:any) => row.E, sortable: true,},
        { name: "Descripción", selector: (row:any) => row.Descripcion, width:"250px", sortable: true, wrap:true},
        { name: "Nivel 1", selector: (row:any) => row.Clasificacion1,  sortable: true, width:"150px"},
        { name: "Nivel 2", selector: (row:any) => row.Clasificacion2, width:"150px", sortable: true}, 
        { name: "Nivel 3", selector: (row:any) => row.Clasificacion, sortable: true, width:"150px"},
        { name: "Unidad", selector: (row:any) => row.Un, sortable: true},
        { name: "Precio", selector: (row:any) => row.Precio, format: (row:any) => row.Precio.toLocaleString(), right: true, sortable: true},
        { name: "Sistema", selector: (row:any) => row.Sistema,  sortable: true, right: true},
        { name: "CC Sistema", selector: (row:any) => row.CCSistema, format: (row:any) => row.CCSistema.toLocaleString(),  sortable: true, right: true, width:"150px"},
        { name: "Bloqueado", selector: (row:any) => row.Bloqueado, format: (row:any) => row.Bloqueado.toLocaleString(), right: true, sortable: true, width:"150px"}, 
        { name: "Valor Sistema", selector: (row:any) => row.ValorSistema, format: (row:any) => row.ValorSistema.toLocaleString(),  sortable: true, right: true, width:"150px"},
        { name: "Físico", selector: (row:any) => row.Fisico, format: (row:any) => row.Fisico.toLocaleString(), sortable: true, right: true, width:"150px"},
        { name: "Valor Físico", selector: (row:any) => row.ValorFisico,  format: (row:any) => row.ValorFisico.toLocaleString(), right: true, sortable: true, width:"150px"},
        { name: "Diferencia", selector: (row:any) => row.Diferencia,  format: (row:any) => row.Diferencia.toLocaleString(),  sortable: true, width:"150px", right: true},
        { name: "Valor Diferencia", selector: (row:any) => row.ValorDiferencia, format: (row:any) => row.ValorDiferencia.toLocaleString(), sortable: true, right: true, width:"150px"},
        { name: "Absoluto", selector: (row:any) => row.Absoluto, format: (row:any) => row.Absoluto.toLocaleString(),  sortable: true, right: true, width:"150px"},
        { name: "Base sistema", selector: (row:any) => row.Base_Sistema, width:"150px", format: (row:any) => row.Base_Sistema.toLocaleString(),  right: true, sortable: true}, 
        { name: "Base CC Sistema", selector: (row:any) => row.Base_CCSistema, format: (row:any) => row.Base_CCSistema.toLocaleString(),  sortable: true, width:"150px", right: true,},
        { name: "Base Bloqueado", selector: (row:any) => row.Base_Bloqueado, format: (row:any) => row.Base_Bloqueado.toLocaleString(),  sortable: true, right: true, width:"150px"},
        { name: "Base Físico", selector: (row:any) => row.Base_Fisico, format: (row:any) => row.Base_Fisico.toLocaleString(),  width:"150px", right: true, sortable: true},
        { name: "Base Precio", selector: (row:any) => row.Base_Precio, format: (row:any) => row.Base_Precio.toLocaleString(), sortable: true, right: true, width:"150px"},  
        { name: "Clasificación Dif.", selector: (row:any) => row.Clasif_Diferencia, format: (row:any) => row.Clasif_Diferencia.toLocaleString(), right: true, sortable: true, width:"150px"},       
        { name: "Acciones", selector: (row:any) => row.Acciones},         
    ];

    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
          setRecords(cpRecords);  
        }else{
            records = [...cpRecords];
            let reg = records.filter((dato: any)=> {
                return (dato.Codigo && dato.Codigo.includes(evnt.target.value.toUpperCase()))
                        || (dato.E && dato.E.includes(evnt.target.value.toUpperCase())) 
                        || (dato.Descripcion && dato.Descripcion.includes(evnt.target.value.toUpperCase()))  
                        || (dato.Clasificacion1 && dato.Clasificacion1.toString().includes(evnt.target.value.toUpperCase())) 
                        || (dato.Clasificacion2 && dato.Clasificacion2.toString().includes(evnt.target.value.toUpperCase()))  
                        || (dato.Clasificacion && dato.Clasificacion.toString().includes(evnt.target.value.toUpperCase()))                        
                        || (dato.Un && dato.Un.toString().includes(evnt.target.value.toUpperCase())) 
                        || (dato.Precio && dato.Precio.toString().includes(evnt.target.value.toUpperCase())) 
                        || (dato.Diferencia && dato.Diferencia.toString().includes(evnt.target.value.toUpperCase()))
                        || (dato.Base_Precio && dato.Base_Precio.toString().includes(evnt.target.value.toUpperCase()));                      
            }); 
            setRecords(reg); 
        }
    } 

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };

    const hndlrPeriodo = async (evnt: any) =>{

        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();      
    }

    const MostrarTodoModalDetalle = (indice: number) =>{
        setElement({...dt[indice]})
        setMsgDlgShow(true);           
    }
    
    const MostrarTodoReconteo = (indice: number) =>{
        setElement({...dt[indice]})
        setMsgDlgShowReconteo(true);        
    }  

    const hndlrBtnBuscar = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ReportesProduccion?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&Diferencias=${sinoDifRec.dif}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        let arrDta = await JSON.parse(response.message);    
        console.log(arrDta); 
        if (response.code >= 400){
            setMsgRespuestInv(arrDta.Message);
            setMsgDlgShowApiResponse(true);
            dispatch(SetDataTable([]));
            setRecords([]);
            setBtnToExce(true);  
        }
        else{
            if (arrDta.Detalle){
                let newArrDtaFilter : any[];
                if (sinoDifRec.rec === "0"){
                    newArrDtaFilter = arrDta.Detalle.filter((obj: any) => obj.Reconteo !== "N");
                }else{
                    newArrDtaFilter = arrDta.Detalle;
                }   
                const auxDta:any = [];
                newArrDtaFilter.map((valor: any, index: number)=>{  
                    const newObj = {
                        ...valor,
                        Acciones: <div  className=' d-flex gap-1' >
                                        <a href="#!" id={`${index}`} onClick={(obj: any)=>{
                                                MostrarTodoModalDetalle(index)
                                            }}>
                                            <FaEye title="Detalle inventario" color={'green'}  className='rounded-circle p-1 h3 '/>   
                                        </a> 
                                        {
                                            (valor.Reconteo !== "N")
                                            ?  <a href="#!" id={`${index}`} onClick={(obj: any)=>{
                                                    MostrarTodoReconteo(index);
                                                }}>
                                                    <FaCircle title="Ver movimientos" color={'red'}  className=' rounded-circle p-1 h4'/>  
                                                </a>  
                                            :  <FaCircle title="Ver movimientos" color={'grey'}  className=' rounded-circle p-1 h4'/>  
                                        }                              
                                                                            
                                    </div>
                    };
                    auxDta.push(newObj);  
                });
                //********Pasa el objeto a arreglo para recorrido en table
                if (auxDta.length > 0){
                    setBtnToExce(false);
                }else{
                    setBtnToExce(true);                
                }
                dt = [...auxDta];
                dispatch(SetDataTable(dt));
                setRecords(auxDta); 
                setResumen(arrDta.Resumen); 
                setCpRecords(auxDta);         
            } 
        }  
    }

    const ValidaBtnAbrir = () => {
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "") && 
            (sinoDifRec.dif !== "") && 
            (sinoDifRec.rec !== "")){
                setHbltBtnAbrir(false);
        }else{
                setHbltBtnAbrir(true);
        }
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{
        
        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()};     
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnAbrir();   
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData); 
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnAbrir();                  
    }

    const hndlrOnChangeSel = (evnt: any) =>{
        sinoDifRec = {...sinoDifRec, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setSinoDifRec(sinoDifRec); 
        ValidaBtnAbrir();                 
    }

    const MsgDlghandlerResponseApi = () =>{
        setMsgDlgShowApiResponse(false);
    }  

    const handlerDlgDetReportOk = () =>{
        setMsgDlgShow(false);    
    } 

    const handlerDlgDetReportReconteoOk = () =>{
        setMsgDlgShowReconteo(false);    
    }    
    
    const digitadoInventario = async()=>{

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/ReportInventario?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message);
        setSHCarga(false);        
        if (response.code >= 400){
            setMsgRespuestInv("No se pudo realizar la operación");
            setMsgDlgShowApiResponse(true);              
        }else{
            if(arrDta.length > 0){
                exportToExcel("tablaDigitadoInventario", arrDta)
            }else{
                setMsgRespuestInv("No hay datos que exportar"); 
                setMsgDlgShowApiResponse(true);                                 
            }
        } 
    } 

    const exportAExcell = (name: string, data: any) =>{

        const newObj: any = [];
        data.map((fila: any)=>{
            const obj = {
                Codigo: fila.Codigo, 
                E: fila.E,       
                Descripcion: fila.Descripcion, 
                Clasificacion1: fila.Clasificacion1,
                Clasificacion2: fila.Clasificacion2,
                Centro: fila.Centro,     
                Almacen: fila.Almacen,    
                Un: fila.Un,                
                Precio: fila.Precio, 
                Sistema: fila.Sistema, 
                CCSistema: fila.CCSistema, 
                Bloqueado: fila.Bloqueado,
                ValorSistema: fila.ValorSistema,
                Fisico: fila.Fisico,
                ValorFisico: fila.ValorFisico,
                Diferencia: fila.Diferencia,
                ValorDiferencia: fila.ValorDiferencia,                
                Clasificacion: fila.Clasificacion,
                Base_Sistema: fila.Base_Sistema,
                Base_CCSistema: fila.Base_CCSistema,
                Base_Bloqueado: fila.Base_Bloqueado,
                Base_Fisico: fila.Base_Fisico,
                Base_Precio: fila.Base_Precio,
                Reconteo: fila.Reconteo,
                Pais: fila.Pais,
                Anno: fila.Anno,
                Mes: fila.Mes,
                Clasif_Diferencia: fila.Clasif_Diferencia
            };
            newObj.push(obj);
        });
        exportToExcel(name, newObj)
    }
    
    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }   
            
            ValidaBtnAbrir();
        }

        preFiltros();   

    }, []);      

    return(
        <>
            <Container fluid>
                <BarraMenuHz/>  
                <div >
                    <div className="text-primary ms-3 mt-3">
                            Home / Consulta / Reporte Inventario
                    </div>
                    <div className="h4 mt-4 mb-4 ms-3">REPORTE DE INVENTARIO</div>
                </div>
                <Container fluid>
                    <Accordion  className="mb-1" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">PARÁMETROS INVENTARIO</div></Accordion.Header>
                            <Accordion.Body>
                                <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                                <div className="align-middle">
                                    <FaCalendarAlt className="h5" /> 
                                    <label className="ms-2 h4 ">Periodo</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" />
                                <div  className="d-flex flex-column col-6">
                                    <label className="m-2 ">Mes/Año</label>
                                    <input type="month" id="periodo" size={8}  className="ms-2"
                                        onChange={hndlrPeriodo}
                                        value={`${formData.Anno}-${formData.Mes}`} 
                                        min="1990-01" max="9999-12"
                                    />
                                </div>
                                <div className="align-middle mt-4">
                                    <FaTruck className="h5" /> 
                                    <label className="ms-2 h4 ">Centro y Almacen</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" /> 

                                <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Centro de distribución *</label>    
                                        <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault}/></div>
                                    </Container>
                                    <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Almacen *</label>    
                                        <div ><SelectAlmacenes id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}  default={emp.almacenDefault} /></div>
                                    </Container>
                                </div>

                                <div className="align-middle mt-4">
                                    <FaCheck className="h5" />  
                                    <label className="ms-2 h4 "> Diferencias y Reconteo</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" /> 
                                                                                
                                <div className="row d-flex flex-row mb-3">
                                    <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Diferencia</label>    
                                        <Form.Select value={sinoDifRec.dif} aria-label="Default select example"  id="dif" onChange={(e)=>hndlrOnChangeSel(e)}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="1">Si</option>
                                            <option value="0">No</option>
                                        </Form.Select>
                                    </Container>
                                    <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Reconteo</label>    
                                        <Form.Select value={sinoDifRec.rec} aria-label="Default select example"  id="rec"  onChange={(e)=>hndlrOnChangeSel(e)}>
                                            <option value="">Seleccione una opción</option>
                                            <option value="0">Si</option>
                                            <option value="1">No</option>
                                        </Form.Select>
                                    </Container>
                                </div>

                                <div className="text-end">
                                    <Button type="button" className=" btn btn-success border border-0" 
                                        disabled={hbltBtnAbrir}  onClick={hndlrBtnBuscar}
                                    >
                                        Filtrar
                                    </Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>         

                    <Accordion  className="mb-1" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">REPORTE</div></Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <div className=" row align-items-center mt-4 ms-3">
                                        <div className="m-1 col-md-12 col-lg-8 " > 
                                            <Button type="button" className="border-0" style={{backgroundColor:"#16D39A"}}
                                                onClick={()=>digitadoInventario()}
                                                disabled={hbltBtnAbrir}
                                            >
                                                Digitado
                                            </Button>
                                        </div>
                                        <div className="m-1 col-md-12 col-lg-8 " > 
                                            <Button type="button" className="border-0" style={{backgroundColor:"#16D39A"}}
                                                disabled={btnToExcel}
                                                onClick={async ()=> exportAExcell("tablaReporteInventario", records)}>
                                                Exportar
                                            </Button>
                                        </div>            
                                    </div>  
                                    <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                        <label className="m-1">Filtro</label>
                                        <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                    </div>                                    
                                    <DataTable
                                        columns={columns}
                                        data={records}
                                        selectableRowsHighlight
                                        fixedHeader
                                        pagination
                                        highlightOnHover
                                        fixedHeaderScrollHeight="600px"
                                        paginationComponentOptions={pagOptions}
                                    />                                                  
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  

                    <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">RESUMEN</div></Accordion.Header>
                            <Accordion.Body  className="p-4 mb-5 ">
                                <div className="row">
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label fs-6">Total Items</label>    
                                        <div><input type="text" name="totItems" id="totItems" className=" form-control text-end" readOnly value={resumen.TotalItems.toLocaleString()}/></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label fs-6">Inv. Físico Valorizado ($)</label>    
                                        <div ><input type="text" name="ifv" id="ifv" className=" form-control text-end"  readOnly  value={resumen.InvFisicoValorizado.toLocaleString("es")} /></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label fs-6">Valores Negativos</label>    
                                        <div ><input type="text" name="valneg" id="valneg" className=" form-control text-end"  readOnly   value={resumen.ValoresNegativos.toLocaleString()}/></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label ">Diferencia Valor Contable ($)</label>    
                                        <div ><input type="text" name="dvc" id="dvc" className=" form-control text-end"   readOnly  value={resumen.DiferenciaValorContable.toLocaleString()}/></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label">Descuadre Inv. Valorizado (%)</label>    
                                        <div ><input type="text" name="div" id="div"  className=" form-control text-end"  readOnly value={resumen.PorcDescuadreInvValorizado.toLocaleString()}/></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label">Items Cuadrados</label>    
                                        <div ><input type="text" name="itcuad" id="itcuad"  className=" form-control text-end"  readOnly  value={resumen.ItemsGuardados.toLocaleString()}/></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label">Inv. Sistema Valorizado ($)</label>    
                                        <div ><input type="text" name="isv" id="isv"  className=" form-control text-end"  readOnly value={resumen.InvSistemaValorizado.toLocaleString()}/></div>
                                    </div>
                                    <div className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label">Valores Positivos</label>    
                                        <div ><input type="text" name="valpos" id="valpos"  className=" form-control text-end"  readOnly  value={resumen.ValoresPositivos.toLocaleString()}/></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 "  >
                                        <label className="form-label">Diferencia Valor Absoluto ($)</label>    
                                        <div ><input type="text" name="dva" id="dva" className=" form-control text-end"  readOnly   value={resumen.DiferenciaValorAbsoluto.toLocaleString()}/></div>
                                    </div>
                                    <div  className="mt-2 mb-2   col-md-12 col-lg-6 " >
                                        <label className="form-label">Cuadratura Items (%)</label>    
                                        <div ><input type="text" name="cuadit" id="cuadit" className=" form-control text-end"  readOnly  value={resumen.PorcCuadraturaItems.toLocaleString()}/></div>
                                    </div> 
                                </div>                                                    
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  
                </Container>                    
            </Container>
            {/*********** cuadro de dialogo para detalle inv **********/}
            {msgDlgShow && <MsgDialogDetalleReporteInv
                Title={`Detalle Inventario - ${formData.Centro}/${formData.Almacen}`} 
                SubTitle={`${formData.Centro}/${formData.Almacen}`}
                Show={msgDlgShow}
                BtnOkName="Aceptar"
                BtnNokName="Cerrar"
                HandlerClickOk={handlerDlgDetReportOk}
                HanlerdClickNok={handlerDlgDetReportOk}
                data={element}
                formData={formData}
            /> }    
            {/*********** cuadro de dialogo para detalle inv **********/}
            {msgDlgShowReconteo && <MsgDialogReconteoRepoInv
                Title={`Reconteo Inventario`} 
                Show={msgDlgShowReconteo}
                BtnOkName="Aceptar"
                BtnNokName="Cerrar"
                HandlerClickOk={handlerDlgDetReportReconteoOk}
                HanlerdClickNok={handlerDlgDetReportReconteoOk}
                data={element}
                formData={formData}
            />}                       
            {/*********** cuadro de dialogo para msj de api **********/}
            {msgDlgShowApiResponse && <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Consulta`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApi}
                HanlerdClickNok={null}
            />}            
            {sHCarga && <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento porfavor, procesando..."
                color="#FF7588"
            /> } 
        </>
    );
}

export default ReporteInventarioPage;
