import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SelectCentro(props: any) {
    
    const emp: any = useSelector((state: any) => state.emp);
    let [valor, setValor] = useState(props.default);

    const handler = (event: any) =>{

      const cent = event.target.value;
      const nroAlm = emp.almacenes.filter((item:string) => item.includes(cent)); 
      setValor(cent);  
      props.OnSeleccion(cent, nroAlm);
      
    }

    useEffect(()=>{

      setValor(props.default);

    }, []);

    return (
      <select  className="form-select "  value={valor} onChange={(e) => handler(e)} id={props.id}>
        <option key={-1} value={""}>Seleccione una opción</option>
        {
            emp.centros.map((opcion: string, index: number)=><option key={index} value={opcion.split(" - ")[0]}>{opcion}</option>)
        }
      </select>
    );
} 

export default SelectCentro;