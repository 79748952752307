import { useEffect, useState } from "react";
import { Accordion, Button, Container } from "react-bootstrap";
import { FaArrowDown, FaCalendarAlt, FaTruck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import { exportToExcel } from "../../../util";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import MsgDialog2 from "../../../components/MsgDialog2";
  

const SaldosMuestrasPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    const [showCuadroDialogoRecargar, setShowCuadroDialogoRecargar] = useState(false);    
    const [showCuadroDialogoActuaizar, setShowCuadroDialogoActuaizar] = useState(false);   
    let [formData, setFormData] = useState({ Pais: "CL", Anno: emp.periodoDefault.substring(0, 4), Mes: emp.periodoDefault.substring(5, 7),  Centro: emp.centroDefault, Almacen: emp.almacenDefault});
    const [sHCarga, setSHCarga] = useState(false); 
    let [records, setRecords] = useState(new Array<any>());  
    const [cpRecords, setCpRecords] = useState(new Array<any>());      
    const [showMuestrasSaldos, setShowMuestrasSaldos] = useState(false);                 
    let [rowsPreselected, setRowsPreselected] = useState(new Array<any>());   
    const dispatch = useDispatch(); 
    const [muestra_ref, setMuestra_ref]  = useState(0);    
    const [user_ref, setUser_ref]  = useState("");   
    const [showResPublicar, setShowResPublicar]  = useState(false);  
    const [msgResPublicar, setMsgResPublicar]  = useState("");                 
         
    let columns = [
        { name: "Muestras Ref.", selector: (row:any) => row.Muestra_ref, sortable: true, right:true, width:"120px"},
        { name: "Bajada Saldos", selector: (row:any) => row.Bajada_saldos.substring(0, 10), sortable: true, width:"130px"},
        { name: "Usuario Bajada", selector: (row:any) => row.Bajada_usuario, sortable: true, width:"150px",},
        { name: "Usuario Apertura", selector: (row:any) => row.Usuario_apertura, sortable: true, width:"190px",},
        { name: "Fecha Creación", selector: (row:any) => row.Publicado_fecha, sortable: true, width:"200px"},            
        { name: "Acción", selector: (row:any) => row.Accion, sortable: true, width:"130px"},                       
    ]

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  

    const ValidaBtnAbrir = () => {     
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "")){     
                setHbltBtnAbrir(false); 
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();                    
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{

        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()};     
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnAbrir();   
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnAbrir();                               
    }   

    const OnRecargar = async () =>{

        setShowMuestrasSaldos(false);        
        setShowCuadroDialogoRecargar(false);
        setSHCarga(true);
        const req = {
            "Pais": "CL",
            "Anno": `${formData.Anno}`,
            "Mes": `${formData.Mes}`,
            "Centro": `${formData.Centro}`,
            "Almacen": `${formData.Almacen}`,
            "Muestra_Referencia": muestra_ref,
            "Usuario": `${user_ref}`
        };
     
        const recurso = `${env.REACT_APP_API_URL}/api/MuestrasInventarioRecargarCodigos`; 
        const response = await httpApiPostText(recurso, "POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, req);
        const arrDta = await JSON.parse(response.message);         

        setSHCarga(false);

        setMsgResPublicar(arrDta);
        setShowResPublicar(true);   
    }

    const OnActualizar = async () =>{

        setShowMuestrasSaldos(false);        
        setShowCuadroDialogoActuaizar(false);
        setSHCarga(true);
        const req = {
            "Pais": "CL",
            "Anno": `${formData.Anno}`,
            "Mes": `${formData.Mes}`,
            "Centro": `${formData.Centro}`,
            "Almacen": `${formData.Almacen}`,
            "Muestra_Referencia": muestra_ref,
            "Usuario": `${user_ref}`
        };
     
        const recurso = `${env.REACT_APP_API_URL}/api/MuestrasInventarioActualizaSaldos`; 
        const response = await httpApiPostText(recurso, "POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, req);
        const arrDta = await JSON.parse(response.message);         

        setSHCarga(false);

        setMsgResPublicar(arrDta);
        setShowResPublicar(true);  
    }
        
    const btnBuscar = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/MuestrasInventarioAvance?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&Estado=T`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message); 
        setSHCarga(false);
        if(response.code >= 400){
            console.log("Error Muestras - 400:", response, recurso);
        }else{     
            const auxDta:any = [];          
            arrDta.map((obj: any, index:number)=>{
                const newObj = {
                    Muestra_ref: obj.Muestra_ref,
                    Bajada_saldos: obj.Bajada_saldos,
                    Bajada_usuario: obj.Bajada_usuario,
                    Usuario_apertura: obj.Usuario_apertura,
                    Publicado_fecha: ((obj.Publicado_fecha.substring(0, 10) === "0001-01-01") ? "" : obj.Publicado_fecha.substring(0, 10)),
                    Accion: <div  className=' d-flex gap-1' >
                                <a href="#!" id={`${index}`}  onClick={()=>{
                                        setMuestra_ref(obj.Muestra_ref);
                                        setUser_ref(obj.Bajada_usuario);
                                        setShowMuestrasSaldos(true);
                                    }}>
                                    <FaArrowDown title="Daescragar saldos" color={'white'}  className=' bg-primary rounded-circle p-1 h4'/>  
                                </a>                                                                     
                            </div>
                };                       
                auxDta.push(newObj);  
            });               
    
            setRecords([...auxDta]);
            setCpRecords([...auxDta]);
        }       
    }

    const changeTextFiltro = (evnt: any) => {

        let reg = cpRecords.filter((dato: any)=> {
            return (dato.Muestra_ref && dato.Muestra_ref.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Bajada_saldos && dato.Bajada_saldos.includes(evnt.target.value.toUpperCase())) 
                || (dato.Bajada_usuario && dato.Bajada_usuario.includes(evnt.target.value.toUpperCase()))  
                || (dato.Usuario_apertura && dato.Usuario_apertura.includes(evnt.target.value.toUpperCase())) 
                || (dato.Publicado_fecha && dato.Publicado_fecha.toString().includes(evnt.target.value.toUpperCase())) 
        });
        setRecords([...reg]); 
    }  

    const onRowSelected = (arr: any) =>{
        rowsPreselected = arr.selectedRows;   
        setRowsPreselected([...rowsPreselected]);   
    }    

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            ValidaBtnAbrir();                   
        }

        preFiltros();          

    }, []);   
    
    const resetPage = () => {

        setShowResPublicar(false);
        btnBuscar();
    }
  
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Muestras / Saldos
                </div>
                <div className="h4 mt-4 mb-4 ms-3">MUESTRAS SALDOS</div>
            </div> 
            <Container fluid>   
                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS MUESTRAS</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>
                            </div>

                            <div className=" col-md-12 col-lg-12 text-end">
                                <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                    disabled={hbltBtnAbrir} onClick={() => btnBuscar()}
                                >
                                    Filtrar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1 mb-5" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">SALDOS</div></Accordion.Header>
                        <Accordion.Body> 
                            <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn mt-1 border-0"
                                        disabled={!(records.length > 0)}
                                        onClick={()=> {exportToExcel("TablaMuestra", records)}}
                                    >
                                        Exportar 
                            </Button>                            
                            <div className="row d-flex flex-row align-middle mt-2 mb-5">
                                <div className="d-flex flex-column col col-lg-6 col-md-12 mb-3 ">
                                    <label className="m-1">Filtro</label>
                                    <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                </div> 
                                <DataTable
                                    columns={columns}
                                    data={records}                
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                    onSelectedRowsChange={onRowSelected}
                                />                                                       
                            </div> 
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>

            <MsgDialog2 
                Title="Muestra Saldos"
                Icon="!"
                Show={showMuestrasSaldos}
                BtnNokName="Actualizar Saldos"
                BtnOkName="Recargar Códigos"
                Message={"Seleccione modo de actualización de saldos"}
                HandlerClickOk={()=>setShowCuadroDialogoRecargar(true)}
                HanlerdClickNok={()=>setShowCuadroDialogoActuaizar(true)}
                HanlerdClickX={()=>setShowMuestrasSaldos(false)}                
            /> 
            <MsgDialog 
                Title="Muestra Saldos"
                Icon="!"
                Show={showCuadroDialogoRecargar}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="¿Está seguro de recargar los códigos para esta muestra?"
                HandlerClickOk={OnRecargar}
                HanlerdClickNok={()=>setShowCuadroDialogoRecargar(false)}
            />    

            <MsgDialog 
                Title="Muestra Saldos"
                Icon="!"
                Show={showCuadroDialogoActuaizar}
                BtnNokName="No, cancelar"
                BtnOkName="Si, continuar"
                Message="¿Está seguro de actualizar los stock de sistema para esta muestra?"
                HandlerClickOk={OnActualizar}
                HanlerdClickNok={()=>setShowCuadroDialogoActuaizar(false)}
            />    

            {/*********** otro cuadro de dialogo para msj de api **********/}
            <MsgModalDialogEspecial
                Show={showResPublicar}
                Title={`Muestras`}
                Icon="x"
                Message={msgResPublicar}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={()=>resetPage()}
                HanlerdClickNok={null}
            />                               

            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />                       
        </Container>    
    );
}

export default SaldosMuestrasPage;