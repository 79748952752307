import { useEffect, useState } from "react";
import { Accordion, Button, Container, Form} from "react-bootstrap";
import { FaCalendarAlt, FaShareAlt, FaTruck } from "react-icons/fa";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import { httpApiGetText } from "../../../lib";
import * as env from '../../../env';
import './style.css';
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import { exportToExcel } from "../../../util";
import DataTable from "react-data-table-component";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { useDispatch, useSelector } from "react-redux";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import SelectCentro from "../../../components/Centros";
import SelectAlmacenes from "../../../components/Almacenes";
import Multiselect from 'multiselect-react-dropdown';

const DescargaMasivaMuestrasPage = () =>{

    let emp: any = useSelector((state: any) => state.emp); 
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    const [sHCarga, setSHCarga] = useState(false);
    let   [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "", Centro: "", Almacen: "", Estado: "", Muestra: "" });
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);   
    const [msgRespuestInv, setMsgRespuestInv] = useState(""); 
    const [showMsgApiError, setShowMsgApiError] = useState(false);    
    const [MsgApiError, setMsgApiError] = useState("");  
    const [cpRecords, setCpRecords] = useState(new Array<any>());  
    let [records, setRecords] = useState(new Array<any>());             
    let [opcionSubMuestras, setOpcionSubMuestras] = useState(new Array());      
    let [muestrasSelected, setMuestrasSelected] = useState(new Array());       
    let [rowsPreselected, setRowsPreselected] = useState(new Array<any>());   
    const [clrRows, setCLRows] = useState(false);     
    const dispatch = useDispatch();   

    let columns = [
        { name: "Período", selector: (row:any) => row.Periodo, sortable: true, width:"90px"},
        { name: "Centro", selector: (row:any) => row.Centro, sortable: true, width:"90px"},
        { name: "Almacén", selector: (row:any) => row.Almacen, sortable: true, width:"100px"},        
        { name: "Código", selector: (row:any) => row.Codigo, sortable: true, width:"90px"},
        { name: "Muestra", selector: (row:any) => row.Muestra_ref, sortable: true, width:"100px"},                                      
        { name: "E", selector: (row:any) => row.E, sortable: true, width:"80px"},        
        { name: "Descripción", selector: (row:any) => row.Descripcion, sortable: true, width:"350px", wrap:true},
        { name: "Unidad", selector: (row:any) => row.Un, sortable: true, width:"90px"},
        { name: "Costo", selector: (row:any) => row.Costo,  format: (row:any) => row.Costo.toLocaleString(), right:true, sortable: true, width:"140px"},            
        { name: "Bloqueado", selector: (row:any) => row.Bloqueado, sortable: true, right:true, width:"120px"},
        { name: "Diferencia", selector: (row:any) => row.Diferencia,  format: (row:any) => row.Diferencia.toLocaleString(),  sortable: true, width:"120px", right:true},
        { name: "Dif. Valorizada", selector: (row:any) => row.Diferencia_Valorizada, format: (row:any) => row.Diferencia_Valorizada.toLocaleString(), right:true, sortable: true, width:"150px"},           
        { name: "Saldo SAP", selector: (row:any) => row.Saldo_SAP, format: (row:any) => row.Saldo_SAP.toLocaleString(), sortable: true, width:"120px", right:true},  
        { name: "Stock Bodega", selector: (row:any) => row.Total_Stock_Bodega, format: (row:any) => row.Total_Stock_Bodega.toLocaleString(), sortable: true, width:"130px", right:true},    
        { name: "Stock SAP", selector: (row:any) => row.Total_Stock_SAP, format: (row:any) => row.Total_Stock_SAP.toLocaleString(), sortable: true, width:"120px", right:true},                           
        { name: "Clasificado", selector: (row:any) => row.Clasificado, format: (row:any) => row.Clasificado.toLocaleString(), sortable: true, width:"130px", right:true},    
        { name: "Clasificar", selector: (row:any) => row.Clasificar, format: (row:any) => row.Clasificar.toLocaleString(), sortable: true, width:"120px", right:true},  
        { name: "Clasificacion", selector: (row:any) => row.Clasificacion, sortable: true, width:"120px"},
        { name: "Motivo", selector: (row:any) => row.Motivo, sortable: true, width:"120px"},     
        { name: "SubMotivo", selector: (row:any) => row.SubMotivo, sortable: true, width:"160px"},   
        { name: "Cantidad Clasificado", selector: (row:any) => row.DetalleClasificacion, sortable: true, width:"150px", right:true},    
        { name: "Autoriza Supervisor", selector: (row:any) => row.usu_autoriza_super, sortable: true, width:"160px"},     
        { name: "Tipo", selector: (row:any) => row.tipo_sel, sortable: true, width:"120px"},                         
    ];

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  
 
    const hndlrBtnBuscar = async () =>{
        setSHCarga(true);

        let strMtr = "";
        muestrasSelected.map((itm: any)=>{
               strMtr += itm.cat + ";";  
        });
        strMtr = strMtr.substring(0, strMtr.length-1);

       const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraMasiva?PAIS=CL&ANNO=${formData.Anno}&MES=${formData.Mes}` + 
                        `&CENTRO=${formData.Centro}&ALMACEN=${formData.Almacen}&MUESTRAS=${strMtr}`;       
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message);        

        console.log(arrDta);
         setSHCarga(false);        
       if (response.code >= 400){
            setMsgRespuestInv("No se pudo recuperar las información solicitada.");
            setMsgDlgShowApiResponse(true);
        }
        else{
            const auxDta:any = [];    
            arrDta.map((obj: any, index:number)=>{
                const newObj = {
                    Periodo:formData.Anno + "-" +  formData.Mes, 
                    Centro:formData.Centro,
                    Almacen:formData.Almacen,                     
                    Codigo: obj.Codigo_Rosen,
                    Muestra_ref: obj.Muestra_ref,                                                                                                 
                    E: obj.Especial,
                    Descripcion: obj.Descripcion,
                    Un: obj.Unidad_Medida,
                    Costo: obj.Costo,
                    Bloqueado: obj.Bloqueado,
                    Diferencia:  obj.Diferencia,
                    Diferencia_Valorizada:obj.Diferencia_Valorizada, 
                    Saldo_SAP:obj.Saldo_SAP,          
                    Total_Stock_Bodega:obj.Total_Stock_Bodega, 
                    Total_Stock_SAP:obj.Total_Stock_SAP,       
                    Clasificado:obj.Clasificado,
                    Clasificar: obj.Clasificar,
                    Clasificacion: obj.Clasificacion,
                    Motivo: obj.Motivo,   
                    SubMotivo: obj.SubMotivo,     
                    DetalleClasificacion: obj.DetalleClasificacion,   
                    usu_autoriza_super: obj.usu_autoriza_super,      
                    tipo_sel: obj.tipo_sel   
                };                       
                auxDta.push(newObj);  
            });               

            setRecords([...auxDta]);
            setCpRecords([...auxDta]);      
        }
    }

    const ValidaBtnAbrir = () => {     
        if (muestrasSelected.length > 0){     
                setHbltBtnAbrir(false); 
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const LLenarSelectorMuestra = async () => {

        if ((formData.Anno !== "") && (formData.Almacen !== "") && (formData.Centro !== "")){ 

            const recurso = `${env.REACT_APP_API_URL}/api/ClasificacionMuestraList?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}` + 
                                `&Almacen=${formData.Almacen}&Estado=T`;     
            const response = await httpApiGetText(recurso,{
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });

            const arrDta = await JSON.parse(response.message);

            if (response.code >= 400){
                setMsgApiError(arrDta.Message);
                setShowMsgApiError(true);
            }else{
                const arrSelectMuestra: any = [];
                arrDta.map((obj: any)=>{
                        arrSelectMuestra.push({cat: obj.Muestra_ref, key: obj.Muestra_ref});
                });
                opcionSubMuestras = [...arrSelectMuestra];
                setOpcionSubMuestras([...opcionSubMuestras]);                
            }
        }else{
            setOpcionSubMuestras([]); 
        }                  
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                        
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);   
        LLenarSelectorMuestra();   
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{

        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()};     
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));     

        LLenarSelectorMuestra();
         
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        LLenarSelectorMuestra();                          
    }    

    const selections = (options: any, item: any) =>{

        muestrasSelected = [...options];
        setMuestrasSelected([...muestrasSelected]);
        ValidaBtnAbrir();
    }

    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.Centro && dato.Centro.includes(evnt.target.value.toUpperCase()))
                    || (dato.Almacen && dato.Almacen.includes(evnt.target.value.toUpperCase())) 
                    || (dato.Total_items && dato.Total_items.toString().includes(evnt.target.value.toUpperCase()))  
                    || (dato.Items_Cuadrados && dato.Items_Cuadrados.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Inventario_fisico_valorizado && dato.Inventario_fisico_valorizado.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Inventario_sistema_valorizado && dato.Inventario_sistema_valorizado.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Diferencia_valor_contable && dato.Diferencia_valor_contable.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Diferencia_valor_absoluto && dato.Diferencia_valor_absoluto.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Porc_descuadre && dato.Porc_descuadre.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Porc_descuadre_valorizado && dato.Porc_descuadre_valorizado.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Porc_cuadratura_items && dato.Porc_cuadratura_items.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Hr && dato.Hr.toString().includes(evnt.target.value.toUpperCase()));                        
            }); 
            setRecords(reg); 
        }
    }  
    
    const onRowSelected = (arr: any) =>{
        rowsPreselected = arr.selectedRows;   
        setRowsPreselected([...rowsPreselected]);   
    }

    const onTableDisplay = (row: any) => (row.Pend_Clasificar === 0) ;

    const exportaExcell = async () =>{

        exportToExcel("DescargaMasivaMuestra", records);
        setSHCarga(false);        
    }

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            LLenarSelectorMuestra(); 
        }

        preFiltros();   

    }, []);       

    return(
        <>
            <Container fluid>
            <BarraMenuHz/>  
                <div >
                    <div className="text-primary ms-3 mt-3">
                            Home / Muestras / Descarga Masiva
                    </div>
                    <div className="h4 mt-4 mb-4 ms-3">DESCARGA MASIVA DE MUESTRAS</div>
                </div> 
                <Container fluid>               
                    <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">PARÁMETROS DE MUESTRAS</div></Accordion.Header>
                            <Accordion.Body>
                                <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                                <div className="align-middle">
                                    <FaCalendarAlt className="h5" /> 
                                    <label className="ms-2 h4 ">Periodo</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" />
                                <div  className="d-flex flex-column col-md-12 col-lg-6">
                                    <label className="m-2 ">Mes/Año</label>
                                    <input type="month" id="periodo" size={8}  className="ms-2"
                                        onChange={hndlrPeriodo}
                                        value={`${formData.Anno}-${formData.Mes}`} 
                                        min="1990-01" max="9999-12"
                                    />
                                </div>
                                <div className="align-middle mt-4">
                                    <FaTruck className="h5" /> 
                                    <label className="ms-2 h4 ">Centro y Almacenes</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" /> 
                                <div className="row d-flex flex-row mb-3">
                                    <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Centro de distribución</label>    
                                        <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                    </Container>
                                    <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Almacen </label>    
                                        <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}  default={emp.almacenDefault} /></div>
                                    </Container>

                                </div> 

                                <FaShareAlt className="h5" /> 
                                <label className="ms-2 h4 ">Muestras</label>
                                <hr className="t-0 m-0 mb-3" />

                                <label className="form-label">Seleccionar Muestra </label>    
                                <div className="w-50">
                                    <Multiselect
                                        isObject={true}
                                        displayValue="key"
                                        options={opcionSubMuestras}
                                        onSelect={selections}
                                        onRemove={selections}
                                    />                                  
                                </div>                           

                                <div className="text-end">
                                    <Button type="button" disabled={hbltBtnAbrir} style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" onClick={hndlrBtnBuscar} >
                                        Buscar
                                    </Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>         

                    <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">DIFERENCIAS</div></Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    <div className=" mt-4 mb-4">
                                                <div className="d-flex gap-4">
                                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn mt-1 border-0"
                                                                disabled={!(records.length > 0)}
                                                                onClick={()=> {exportaExcell()}}
                                                            >
                                                                Exportar 
                                                    </Button>                                                                 
                                                </div>
                                                <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                                    <label className="m-1">Filtro</label>
                                                    <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                                </div>                                                      
                                    </div>            
                                    <DataTable
                                        columns={columns}
                                        data={records}                
                                        selectableRowsHighlight
                                        fixedHeader
                                        pagination
                                        highlightOnHover
                                        fixedHeaderScrollHeight="600px"
                                        paginationComponentOptions={pagOptions}
                                        onSelectedRowsChange={onRowSelected}
                                        clearSelectedRows={clrRows}
                                        selectableRowDisabled={onTableDisplay}
                                    />    
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>  
                </Container>                     
            </Container>
            {/*********** cuadro de dialogo para msj de api **********/}
            <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Informe de saldos`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={()=>setMsgDlgShowApiResponse(false)}
                HanlerdClickNok={null}
            />           
            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />               
        </>
    );
}

export default DescargaMasivaMuestrasPage;
