import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useState } from 'react';
import { formatDate, formatTime } from '../../util';

const ModalDiaHora = (props: {Show: boolean, Title: string, Icon: string, Message: string, 
                  BtnOkName: string, BtnNokName: string, 
                  HandlerClickOk: any, HanlerdClickNok: any}) => {
     
    let [dyh, setDyH] = useState({fecha: formatDate(new Date()), hora: formatTime(new Date())});            

    const hndlerDate = async(evnt: any) =>{
        const f: Date = evnt.target.value; 
        const dh = {...dyh, [evnt.target.id]: (evnt.target.id === "fecha") ? formatDate(new Date(f)) :  formatTime(new Date(`${dyh.fecha} ${f}`)) };
        setDyH({...dh});
    } 

 return (
      <div>
        <Modal show={props.Show} centered={true} >
            <Modal.Header  >
                <Modal.Title className='h5 text-center'>
                    {                        
                        props.Title
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row text-center'>
                    <div className='col-lg-6 col-md-12'>
                        <label className="m-2 h6 ">Fecha</label>
                        <input type="date" id="fecha" size={8}  className="ms-2 mb-1" onChange={hndlerDate} value={dyh.fecha}/>   
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <label className="m-2 h6 ">Hora</label>
                        <input type="time" id="hora" size={8}  className="ms-2 mb-1 " onChange={hndlerDate}  value={dyh.hora}/>   
                    </div>                    
                </div>
            </Modal.Body>                
            <Modal.Footer className=''>
                {
                    (props.BtnOkName) 
                        ? 
                        <Button type="button" className="btn btn-success btnColorOk" onClick={()=>props.HandlerClickOk(dyh)}>
                            {props.BtnOkName}
                        </Button>
                        : <div></div>
                }      
                {
                    (props.BtnNokName) 
                        ? 
                        <Button type="button" className="btn btn-danger btnColorNOk" onClick={props.HanlerdClickNok}>
                            {props.BtnNokName}
                        </Button>
                        : <div></div>
                }                                      
            </Modal.Footer>
        </Modal>
      </div>
  );
}

export default ModalDiaHora;