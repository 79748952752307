import { useEffect, useState } from "react";
import { Accordion, Button, Container} from "react-bootstrap";
import { FaCalendarAlt, FaTimesCircle, FaTruck } from "react-icons/fa";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import { httpApiGetText, httpApiPostText } from "../../../lib";
import * as env from '../../../env';
import DataTable from "react-data-table-component";
import MsgDialogEspecial from "../../../components/MsgDialogEspecial";
import { useDispatch, useSelector } from "react-redux";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
 
   
const TratamientoEtiquetaPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true);
    const [hbltBtnAbrir2, setHbltBtnAbrir2] = useState(true);    
    const [sHCarga, setSHCarga] = useState(false);
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    const [msgRespuestInv, setMsgRespuestInv] = useState("");  
    const [records, setRecords] = useState([]); 
    const [cpRecords, setCpRecords] = useState([]);  
    let   [subinventario, setSubinventario] = useState([]); 
    let [rowSelected, setRowSelected] = useState([]);     
    const [msgDlgShowGen, setMsgDlgShowGen] = useState(false); 
    const [msgDlgNoSelected, setMsgDlgNoSelected] = useState(false);   
    const [msgDlgShowCambio, setMsgDlgShowCambio] = useState(false); 
    const [filtro, setFiltro] = useState([""]);     
    const [body, setBody] = useState({});  
    let [formData, setFormData] = useState({ Pais: "CL", Anno:  "", Mes:  "",  Centro:  "", Almacen: "", Subinventario_id: ""});
    let [formData2, setFormData2] = useState({ Centro2: "", Almacen2: ""}); 
    const dispatch = useDispatch();                  
    
    let columns = [
        { name: "Código", selector: (row:any) => row.Inv_item_id, sortable: true},
        { name: "Descripción", selector: (row:any) => row.Descripcion, width:"250px", sortable: true, wrap:true},
        { name: "Serial", selector: (row:any) => row.Serial, sortable: true, width:"300px", wrap:true},
        { name: "Cantidad", selector: (row:any) => row.Cantidad,  format: (row:any) => row.Cantidad.toLocaleString(), sortable: true, right:true, width:"150px"},
        { name: "Acciones", selector: (row:any) => row.Acciones, grow:2},
    ];

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  
    
    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.Inv_item_id && dato.Inv_item_id.includes(evnt.target.value.toUpperCase()))
                 || (dato.Descripcion && dato.Descripcion.includes(evnt.target.value.toUpperCase()))  
                 || (dato.Serial && dato.Serial.includes(evnt.target.value.toUpperCase())) 
                 || (dato.Cantidad && dato.Cantidad.toString().includes(evnt.target.value.toUpperCase()));                             
            });
            setRecords(reg); 
        }
    } 
    
    const ValidaBtnAbrir = () => {
        setFormData2({ Centro2: "", Almacen2: ""});
        setFiltro([]); 
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "") && 
            (formData.Subinventario_id !== ""))
            {
                setHbltBtnAbrir(false);
        }else{
                setHbltBtnAbrir(true);
        }
    }

    const ValidaBtnAbrir2 = () => {
        if ((formData2.Almacen2 !== "") && (formData2.Centro2 !== "")){
                setHbltBtnAbrir2(false);
        }else{
                setHbltBtnAbrir2(true);
        }
    }
    
    const btnBorrarEtiqueta = async (bdy:any) => {
        setMsgDlgShowGen(false);
        const body = {
            ...formData,
            Inv_item_id:bdy.Inv_item_id,
            Nro_documento: bdy.Nro_documento,
            Serial:bdy.Serial,
            Cantidad:bdy.Cantidad,
            Usuario: emp.user
        };
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/EtiquetasSubId`;     
        const response = await httpApiPostText(recurso, "DELETE", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, body);
        if (response.code >= 400){

            setMsgRespuestInv(JSON.parse(response.message).Message);
        }else{
            setMsgRespuestInv("La etiqueta ha sido eliminada con éxito");            
        }
        setSHCarga(false);
        hndlrBtnBuscar();
        setMsgDlgShowApiResponse(true);

    } 
    
    const borraEtiquetaPickeada = async (bdy:any)=>{
        setMsgDlgShowGen(true);
        setBody(bdy);  
    }   
        
    const hndlrBtnBuscar = async () =>{

        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/EtiquetasSubId?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&Subinventario_Id=${formData.Subinventario_id}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);

        const arrDta = await JSON.parse(response.message);     
        if (response.code >= 400){
            setMsgRespuestInv(arrDta);
            setMsgDlgShowApiResponse(true);
        }
        else{      
            const auxDta:any = [];
            arrDta.map((obj: any, index: number)=>{
                const newObj = {
                    ...obj, 
                    Acciones: <div>                                                                     
                                <a href="#!" id={`${index}`}  onClick={()=>{
                                        borraEtiquetaPickeada(obj);
                                    }} >
                                    <FaTimesCircle title="Elimina etiqueta" color={'white'}   className='bg-danger rounded-circle p-1 h4'/>
                                </a>   
                             </div>
                };
                auxDta.push(newObj);
            });            
            setRecords(auxDta);    
            setCpRecords(auxDta);                   
        }
        setFormData2({ Centro2: "", Almacen2: ""});

    }

    const OnSubInventario = (e: any) => {

        formData = {...formData, Subinventario_id: e.target.value};
        setFormData({...formData});
        subInventario();
    }

    const subInventario = async () =>{

        if ((formData.Anno !== "") && (formData.Almacen !== "") && (formData.Centro !== "")){

            const recurso = `${env.REACT_APP_API_URL}/api/PeriodoSub?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&Estado=T`;     
            const response = await httpApiGetText(recurso,{
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            });   
            if (response.code < 400){
                let arrDta = await JSON.parse(response.message);               
                setSubinventario(arrDta);
            }
        }

        ValidaBtnAbrir();
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));         

        sessionStorage.setItem("entorno", JSON.stringify(emp));   

        formData = {...formData, Anno: yy, Mes: mm};
        setFormData({...formData});
        subInventario();                   
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{

        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()};     
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));          

        subInventario();        
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData({...formData}); 

        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        subInventario();                         
    }

    const MsgDlghandlerResponseApi = () =>{
        setMsgDlgShowApiResponse(false);
    } 

    const rowSelectedDelete = (obj: any) =>{
        setRowSelected(obj.selectedRows);
    } 

    const cmbRowsSelected = () =>{
        if (rowSelected.length > 0){
            setMsgDlgShowCambio(true);
        }else{
            setMsgDlgNoSelected(true);
        }
    }    
    
    const btnCambiaEtiquetas = async() =>{

        setMsgDlgShowCambio(false);  
        setSHCarga(true);       

        const arrAux: { 
            Pais: string; 
            Anno: any; 
            Mes: any; 
            Centro: any; 
            Almacen: any; 
            AlmacenDest: any; 
            Inv_item_id: any; 
            Nro_documento: any; 
            Serial: any; }[] = [];

        rowSelected.map((obj: any)=>{
            const newObj = {
                Pais: "CL",
                Anno: obj.Anno,
                Mes: obj.Mes,
                Centro: obj.Centro,
                Almacen: obj.Almacen,
                AlmacenDest: formData2.Almacen2.split("-")[0].trim(),
                Inv_item_id: obj.Inv_item_id,
                Nro_documento: obj.Nro_documento,
                Serial: obj.Serial
            };
            arrAux.push(newObj);
        });

        const recurso = `${env.REACT_APP_API_URL}/api/EtiquetasSubId`;     
        const response = await httpApiPostText(recurso, "POST", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, arrAux);

        setSHCarga(false);

        const arrDta = await JSON.parse(response.message); 
         
        if (response.code >= 400 ){
            setMsgRespuestInv(arrDta.Message);
        }else{
            setMsgRespuestInv("Cambio realizado con éxito");
            rowSelected = [];
            setRowSelected([...rowSelected]);
            hndlrBtnBuscar();
        }  
        setMsgDlgShowApiResponse(true);
        setHbltBtnAbrir2(true);
    } 

    const c2Handler = (event: any) =>{
        formData2 = {...formData2,  Centro2: event.target.value};
        setFormData2({...formData2});
        // Filtra almacenes según Centro
        const cnt = formData2.Centro2.split("-")[0].trim();
        let faux: any[] = emp.almacenes.filter((ce: any)=>ce.substring(0, 4) === cnt.substring(0, 4)); 

        let auxFaux: any[] = [];
        faux.map((cad: string)=>{
           auxFaux.push(cad.split(" - ")[1] + " - " + cad.split(" - ")[2])
        }); 
 
        setFiltro([...auxFaux]);     
        ValidaBtnAbrir2();     
    }

    const AlmaHandler = (event: any) =>{
        formData2 = {...formData2, Almacen2: event.target.value}
        setFormData2({...formData2});
        ValidaBtnAbrir2(); 
    }

    useEffect(()=>{

        const preFiltros = () =>{
          
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }     

            subInventario();
        }
        preFiltros();   


    }, []);    
   
    return(
        <>
            <Container fluid>
            <BarraMenuHz/>  
                <div >
                    <div className="text-primary ms-3 mt-3">
                            Home / Inventario / Tratamiento Etiquetas
                    </div>
                    <div className="h4 mt-4 mb-4 ms-3">TRATAMIENTO DE ETIQUETAS</div>
                </div>  
                <Container fluid >
                    <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">PARÁMETROS INVENTARIO</div></Accordion.Header>
                            <Accordion.Body>
                                <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                                <div className="align-middle">
                                    <FaCalendarAlt className="h5" /> 
                                    <label className="ms-2 h4 ">Periodo</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" />
                                <div  className="row">
                                    <div  className="d-flex flex-column col-lg-6 col-md-12">
                                        <label className="m-2 ">Mes/Año</label>
                                        <input type="month" id="periodo" size={8}  className="ms-2"
                                            value={`${formData.Anno}-${formData.Mes}`}
                                            onChange={hndlrPeriodo} 
                                            min="1990-01" max="9999-12"
                                        />                                        
                                    </div>
                                </div>

                                <div className="align-middle mt-4">
                                    <FaTruck className="h5" /> 
                                    <label className="ms-2 h4 ">Centro y Almacen</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" /> 

                                <div className="row mb-3">
                                    <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Centro de distribución</label>    
                                        <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault}/></div>
                                    </Container>

                                    <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Almacén </label>    
                                        <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro} OnSeleccion={hndlrOpcionAlmacen}  default={emp.almacenDefault} /></div>
                                    </Container>

                                    <div  className="d-flex flex-column col-lg-6 col-md-12">
                                        <label className="m-2 ">SubInventario</label>
                                        <select  className="form-select "  aria-label="Default select example" value={formData.Subinventario_id} onChange={OnSubInventario} id="Subinventario_id">
                                            <option key={-1} value={""}>Seleccione una opción</option>
                                            {
                                                 subinventario.map((opcion: any, index: number)=><option key={index} value={opcion.Subinventario_Id}>{opcion.Subinventario_Id}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                        disabled={hbltBtnAbrir} onClick={() => hndlrBtnBuscar()}
                                    >
                                        Filtrar
                                    </Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>         

                    <Accordion className="mt-3" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark"></div></Accordion.Header>
                            <Accordion.Body>
                                <div className="row d-flex flex-row mb-3">
                                    <Container fluid className=" mb-3 col-md-12 col-lg-6 align-middle" >
                                        <label className="form-label">Centro</label>    
                                        <div>
                                            <select  className="form-select "  aria-label="Default select example" value={formData2.Centro2} onChange={(e) => c2Handler(e)} id="Centro2">
                                                <option key={-1} value={""}>Seleccione una opción</option>
                                                {
                                                    emp.centros.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                                }
                                            </select>
                                        </div>
                                    </Container>
                                    <Container fluid className=" mb-3 col-md-12 col-lg-6 align-middle" >
                                            <label className="form-label">Almacén</label>    
                                            <div>
                                                <select   className="form-select "  value={formData2.Almacen2} aria-label="Default select example" onChange={(e) => AlmaHandler(e)}  id="Almacen2">
                                                        <option key={-1} value={""}>Seleccione una opción</option>
                                                        {
                                                            (filtro.length > 0) 
                                                            ? filtro.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                                            : null
                                                        }
                                                </select>
                                            </div> 
                                    </Container>
                                    <div className="text-end">
                                        <Button type="button" style={{backgroundColor:"#00B5B8"}}  disabled={hbltBtnAbrir2}  className=" btn border border-0" 
                                            onClick={cmbRowsSelected}
                                        >
                                            Cambiar
                                        </Button>                                        
                                    </div>     
                                    <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                        <label className="m-1">Filtro</label>
                                        <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                    </div>                                                                
                                </div>                            
                                <DataTable
                                        columns={columns}
                                        data={records}
                                        selectableRows
                                        selectableRowsHighlight
                                        fixedHeader
                                        pagination
                                        highlightOnHover
                                        fixedHeaderScrollHeight="600px"
                                        paginationComponentOptions={pagOptions}
                                        onSelectedRowsChange={(e: any)=>rowSelectedDelete(e)}
                                    />  
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> 
                </Container> 
            </Container>
            {/*********** cuadro de dialogo para pregunta genérica**********/}
            {msgDlgShowGen && <MsgDialogEspecial
                Show={msgDlgShowGen}
                Title={`Tratamiento Etiquetas`}
                Icon="!"
                Message={"¿Desea eliminar Etiqueta Pickeada?"}
                BtnOkName={"Sí. Eliminar"}
                BtnNokName="No, cancelar"
                HandlerClickOk={btnBorrarEtiqueta}
                HanlerdClickNok={() => setMsgDlgShowGen(false)}
                body={body}
            />}     
            {/*********** cuadro de dialogo para pregunta de cambio**********/}
            {msgDlgShowCambio && <MsgDialogEspecial
                Show={msgDlgShowCambio}
                Title={`Tratamiento Etiquetas`}
                Icon="!"
                Message={"¿Desea cambiar Centro/Almacen de etiqueta(s) seleccionada(s)?"}
                BtnOkName={"Sí. Cambiar"}
                BtnNokName="No, cancelar"
                HandlerClickOk={btnCambiaEtiquetas}
                HanlerdClickNok={() => setMsgDlgShowCambio(false)}
                body={body}
            />}                            
            {/*********** cuadro de dialogo para msj de api **********/}
            {msgDlgShowApiResponse && <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Inventario`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApi}
                HanlerdClickNok={null}
            />}
            {msgDlgNoSelected && <MsgModalDialogEspecial
                Show={msgDlgNoSelected}
                Title={`Tratamiento etiquetas`}
                Icon="x"
                Message={"No ha seleccionado etiqueta. Debe seleccionar al menos una etiqueta."}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={()=> setMsgDlgNoSelected(false)}
                HanlerdClickNok={null}
            />}                  
            {sHCarga && <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento porfavor, procesando..."
                color="#FF7588"
            />}               
        </>
    );
}

export default TratamientoEtiquetaPage;
