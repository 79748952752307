
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import * as env from '../../../../env';
import { httpApiGetText } from '../../../../lib';
import { exportToExcel } from '../../../../util';
import DetalleProducto from '../DetalleProducto';
import '../MsgModalAvanceYcierreReconteo/style.css';

const MsgModalAvanceYcierreReconteo = (
            props: {Show: boolean, Title: string, frmData: any,
            BtnOkName: string, BtnNokName: string, data:any
            HandlerClickOk: any, HanlerdClickNok: any}) =>{

  let [records, setRecords] = useState([]);
  const [cpRecords, setCpRecords] = useState([]);  
  const [sHCarga, setSHCarga] = useState(false);  

  let columns = [
    { name: "ID", selector: (row:any) => row.Reconteo_ref, sortable: true, width:"60px"},
    { name: "Código", selector: (row:any) => row.Inv_item_id, sortable: true, width:"90px"},
    { name: "Descripción", selector: (row:any) => row.Descr60, sortable: true, width:"210px", wrap: true},
    { name: "Especial", selector: (row:any) => row.Especial,  sortable: true, right: true, width:"90px",},
    { name: "Saldo SAP", selector: (row:any) => row.Saldo_sap, format: (row:any) => row.Saldo_sap.toLocaleString(), width:"110px",  sortable: true, right: true}, 
    { name: "Control de calidad SAP", selector: (row:any) => row.Control_cal_sap, format: (row:any) => row.Control_cal_sap.toLocaleString(),  sortable: true, width:"180px", right: true},     
    { name: "Bloqueado", selector: (row:any) => row.Bloqueado, format: (row:any) => row.Bloqueado.toLocaleString(), sortable: true, right: true, width:"110px"},
    { name: "Saldo Físico", selector: (row:any) => row.Saldo_inv, format: (row:any) => row.Saldo_inv.toLocaleString(), width:"120px",  sortable: true, right: true}, 
    { name: "Diferencia", selector: (row:any) => row.Diferencia_Fisico, format: (row:any) => row.Diferencia_Fisico.toLocaleString(),  sortable: true, width:"110px", right: true},   
  ]

  const pagOptions = {
      rowsPerPageText: "Filas por páginas",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos"
  }; 

  const changeTextFiltro = (evnt: any) => {
      if (evnt.target.value.trim() === ""){
          setRecords(cpRecords);  
      }else{
          let reg = cpRecords.filter((dato: any)=> {
              return (dato.Reconteo_ref && dato.Reconteo_ref.toString().toString().includes(evnt.target.value.toUpperCase()))
                  || (dato.Inv_item_id && dato.Inv_item_id.toString().includes(evnt.target.value.toUpperCase())) 
                  || (dato.Descr60 && dato.Descr60.includes(evnt.target.value.toUpperCase()))  
                  || (dato.Especial && dato.Especial.toString().includes(evnt.target.value.toUpperCase())) 
                  || (dato.Saldo_sap && dato.Saldo_sap.toString().toString().includes(evnt.target.value.toUpperCase()))                     
                  || (dato.Control_cal_sap && dato.Control_cal_sap.toString().includes(evnt.target.value.toUpperCase())) 
                  || (dato.Bloqueado && dato.Bloqueado.toString().includes(evnt.target.value.toUpperCase())) 
                  || (dato.Saldo_inv && dato.Saldo_inv.toString().includes(evnt.target.value.toUpperCase()))
                  || (dato.Diferencia_Fisico && dato.Diferencia_Fisico.toString().includes(evnt.target.value.toUpperCase()))                  ;                    
          }); 
          setRecords(reg); 
      }
  } 

  const ExpandedComponent = (evt: any) =>{
    return <DetalleProducto Codigo={evt.data.Inv_item_id} formData={props.frmData} />;
  }

  const onhide = () =>{
    props.HandlerClickOk();
  }

  useEffect(()=>{

    const llamadaApi = async () =>{
      setSHCarga(true);
      const recurso = `${env.REACT_APP_API_URL}/api/Reconteos?pais=CL&Anno=${props.data.Anno}&Mes=${props.data.Mes}` + 
                      `&Centro=${props.data.Centro}&Almacen=${props.data.Almacen}&Reconteo=${props.data.Reconteo_ref}`;  
      const response = await httpApiGetText(recurso, {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
    });
      const arrDta = await JSON.parse(response.message); 
      setSHCarga(false);
      const auxDta:any = [];
      arrDta.RConsolidadoReconteo.map((obj: any, index: number)=>{
        const newObj = {
          Reconteo_ref: obj.Reconteo_ref, 
          Inv_item_id : obj.Inv_item_id, 
          Descr60 : obj.Descr60,
          Especial: obj.Especial,
          Saldo_sap: obj.Saldo_sap,
          Control_cal_sap: obj.Control_cal_sap,
          Bloqueado: obj.Bloqueado,
          Saldo_inv: obj.Saldo_inv,
          Diferencia_Fisico: obj.Diferencia_Fisico,
        };
        auxDta.push(newObj);
      });
      setRecords(auxDta);
      setCpRecords(auxDta);
      setSHCarga(false);
    }; 

    if (props.Show){
      llamadaApi();
    }

    return ()=> {
        records = [];
    }
  }, [props.Show]);  

  return(
      <>
        <Modal  
                show={props.Show} 
                centered 
                size='xl'
                style={{width: "90%"}}
                onHide={() => onhide()}
        >
          <Modal.Header closeButton>
            <Modal.Title>{props.Title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className='mt-1'>
                  <div className='row'>
                      <div className='row'>
                          <div className="col col-lg-6 col-md-12 mb-2">
                              <label className="m-1">Filtro</label>
                              <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                          </div>
                          <div className="col col-lg-6 col-md-12 d-flex justify-content-end align-items-center">
                            <div>
                              <Button className="btn btn-success" onClick={()=>{ 
                                      if (records.length > 0 )
                                        exportToExcel("tablaProductoReconteo", records)
                                      }
                                  }>
                                    Exportar
                              </Button>                              
                            </div>
                          </div> 
                      </div>
                         
                      <div>
                        <DataTable
                              columns={columns}
                              data={records}
                              fixedHeader
                              pagination
                              highlightOnHover
                              fixedHeaderScrollHeight="600px"
                              paginationComponentOptions={pagOptions}
                              expandableRows
                              expandOnRowClicked={false}
                              expandableRowsComponent={ExpandedComponent}
                        />                     
                      </div>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
{/*             <Button  onClick={onhide} className="btn btn-success">
              {props.BtnOkName}
            </Button>   */}        
          </Modal.Footer>
        </Modal>
        { sHCarga && <LocalMsgModalSpinner 
            Show={sHCarga}
            text="Un momento por favor, verificando detalle..."
            color="#FF7588"
        /> }       
      </>
  )

}

export default MsgModalAvanceYcierreReconteo;
