
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function SelectAlmacenes(props?: any) {

  const emp: any = useSelector((state: any) => state.emp);
  const [valor, setValor] = useState("");
  const [filtro, setFiltro] = useState([""]);

  useEffect(()=>{

    if (props.default !== ""){
      setValor(props.default);     
    }

  },[]);

  useEffect(()=>{

    const filtros = () => {

      if (props.centro !== ""){
        // extrae el código del centro
         const cnt = props.centro.split("-")[0].trim();   
         let faux: any[] = emp.almacenes.filter((centro: any)=>{
            return centro.substring(0, 4) === cnt.substring(0, 4);
          }); 

          // de acuedo al centro, filtra los almacenes asociados a ese centro
         let auxFaux: any[] = [];
         faux.map((cad: string)=>{
            auxFaux.push(cad.split(" - ")[1] + " - " + cad.split(" - ")[2]);
         });
         
         setFiltro([...auxFaux]);  

         if (auxFaux.length === 1){
            setValor(auxFaux[0]);
            if (props.updateAlmacen){
                props.updateAlmacen(faux[0].split(" - ")[1])
            }
         }  
      }else{
          setFiltro([]);  
      }
    }

    filtros();

  },[props]);

  const handler = (event: any) =>{

    setValor(event.target.value);
    props.OnSeleccion(event);
  }   

    return (
      <select   className="form-select "  value={valor} aria-label="Default select example" onChange={(e) => handler(e)}  id={props.id}>
        {(filtro.length > 1) && <option key={-1} value={""}>Seleccione una opción</option>}
        {
            (filtro.length > 0) 
              ? filtro.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
              : null
        }
      </select>
    );
} 

export default SelectAlmacenes;

