import { useEffect, useState } from "react";
import { Accordion, Button, Container} from "react-bootstrap";
import { FaCalendarAlt, FaDownload, FaEye, FaTimesCircle, FaTruck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import { httpApiGetText, httpApiPostText } from "../../../lib";
import { SetDataTable, SetEntornoEmp } from "../../../redux/store/Actions";
import * as env from '../../../env';
import DataTable from "react-data-table-component";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import MsgModalAvanceYcierreReconteo from "./MsgModalAvanceYcierreReconteo";
import MsgDialogEspecial from "../../../components/MsgDialogEspecial";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import BarraMenuHz from "../../../components/BarraMenoHz";

const bd = {
    Pais:"",
    Anno:"",
    Mes:"",
    Centro: "",
    Almacen:"",
    Reconteo_ref:"",
    Publicado_usuario:""
}

const AvanceCierreReconteoPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [body, setBody] = useState(bd);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true);
    const [sHCarga, setSHCarga] = useState(false);
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    const [msgDlgShowGen, setMsgDlgShowGen] = useState(false);    
    const [msgRespuestInv, setMsgRespuestInv] = useState("");
    let dt: any = useSelector((state: any) => state.datatable); 
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: ""}); 
    const [records, setRecords] = useState([]); 
    const [cpRecords, setCpRecords] = useState([]);       
    const [showModalProductos, setShowModalProductos] = useState(false);
    const [filaData, setFilaData] = useState();
    const [varGenerica, setVarGenerica] = useState(0);
    const dispatch = useDispatch();  

    let columns = [
        { name: "Id", selector: (row:any) => row.Reconteo_ref, sortable: true, width: "90px"},
        { name: "Fecha Creación", selector: (row:any) => row.Bajada_saldos, sortable: true, width:"150px"},
        { name: "Usuario Creación", selector: (row:any) => row.Bajada_usuario, width:"150px", sortable: true},
        { name: "Fecha Publicación", selector: (row:any) => row.Publicado_fecha,  sortable: true, width:"150px"},
        { name: "Usuario Publicación", selector: (row:any) => row.Publicado_usuario, right:true, width:"180px", sortable: true}, 
        { name: "Centro", selector: (row:any) => row.Centro, sortable: true, right:true, width:"150px"},
        { name: "Almacen", selector: (row:any) => row.Almacen, sortable: true, right:true, width:"150px"},
        { name: "Estado", selector: (row:any) => row.Estado_inv, right:true, sortable: true, width:"150px"},
        { name: "Acciones", selector: (row:any) => row.Acciones, width:"150px"},
    ]

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };   
    
    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.Reconteo_ref && dato.Reconteo_ref.toString().includes(evnt.target.value.toUpperCase()))
                    || (dato.Bajada_saldos && dato.Bajada_saldos.includes(evnt.target.value.toUpperCase())) 
                    || (dato.Bajada_usuario && dato.Bajada_usuario.toString().includes(evnt.target.value.toUpperCase()))  
                    || (dato.Publicado_fecha && dato.Publicado_fecha.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.Publicado_usuario && dato.Publicado_usuario.toString().includes(evnt.target.value.toUpperCase()))                     
                    || (dato.Centro && dato.Centro.includes(evnt.target.value.toUpperCase())) 
                    || (dato.Almacen && dato.Almacen.includes(evnt.target.value.toUpperCase())) 
                    || (dato.Estado_inv && dato.Estado_inv.toString().includes(evnt.target.value.toUpperCase()));                    
            }); 
            setRecords(reg); 
        }
    }  
    
    const ValidaBtnFiltrat = () => {
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "")){
                setHbltBtnAbrir(false);
        }else{
                setHbltBtnAbrir(true);
        }
    }    

    const hndlrPeriodo = async (evnt: any) =>{

        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
        
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnFiltrat(); 
    }

    const AbreModalproductosReconteo = (idx: number) => {
        setFilaData(dt[idx]);
        setShowModalProductos(true);
    }    

    const Cierre = (idx: number)=>{
        setVarGenerica(1);
        setMsgDlgShowGen(true);
        setBody(dt[idx]); 
    }

    const Elimina = (idx: number)=>{
        setVarGenerica(2);
        setMsgDlgShowGen(true);
        setBody(dt[idx]); 
    }   
    
    const btnSiCerraReconteo = async()=>{
        setMsgDlgShowGen(false);
        setSHCarga(true);
        const bdy = {
            Pais:"CL",
            Anno:body.Anno,
            Mes:body.Mes,
            Centro: body.Centro,
            Almacen:body.Almacen,
            Reconteo_ref:body.Reconteo_ref,
            Publicado_usuario:emp.user,            
        };
        const recurso = `${env.REACT_APP_API_URL}/api/Reconteos`;     
        const response = await httpApiPostText(recurso,"PUT", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        const arrDta = await JSON.parse(response.message); 
        setSHCarga(false);
        if (response.code >= 400){
            setMsgRespuestInv(arrDta.Message);
        }else{
            setMsgRespuestInv(arrDta);
            hndlrBtnFiltrar();
        }
        setMsgDlgShowApiResponse(true);
    }

    const btnEliminarReconteo = async()=>{
        setMsgDlgShowGen(false);
        setSHCarga(true);
        const bdy = {
            Pais:"CL",
            Anno:body.Anno,
            Mes:body.Mes,
            Centro: body.Centro,
            Almacen:body.Almacen,
            Reconteo_ref:body.Reconteo_ref,          
        };
        const recurso = `${env.REACT_APP_API_URL}/api/Reconteos`;     
        const response = await httpApiPostText(recurso,"DELETE", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message); 
        if (response.code >= 400){
            setMsgRespuestInv(arrDta.Message);
        }else{
            setMsgRespuestInv("Proceso Ok.");
            hndlrBtnFiltrar();
        }
        setMsgDlgShowApiResponse(true);        
    }   

    const hndlrBtnFiltrar = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Reconteos?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&Estado_inv=&Bajada_estado=`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);
        const auxDta:any = [];
        if (response.code >= 400){
            setMsgRespuestInv(arrDta.Message);
            setMsgDlgShowApiResponse(true);
            dt = [...auxDta];
            dispatch(SetDataTable(dt));
            setRecords(auxDta);              
        }
        else{
            if (arrDta.length > 0){  
                arrDta.map((valor: any, index: number)=>{
                    const newObj = {
                        ...valor, 
                        Publicado_fecha: (valor.Publicado_fecha.includes("0001-01-01")) ? "" : (valor.Publicado_fecha.substring(5, 7) + "/" + valor.Publicado_fecha.substring(8, 10) + "/" + valor.Publicado_fecha.substring(0, 4)),
                        Acciones:<div  className=' d-flex gap-1' >
                                    <a href="#!" id={`${index}`} onClick={(obj: any)=>{
                                                    AbreModalproductosReconteo(index);
                                                }}>
                                                <FaEye title="Ver" color={'white'}  className='bg-success rounded-circle p-1 h4'/>   
                                    </a>                            
                                    {(valor.Estado_inv === "A") 
                                        ?   <a href="#!" id={`${index}`}  onClick={(obj: any)=>{
                                                Cierre(index);
                                            }}>
                                                <FaDownload title="Cierre" color={'white'}  className=' bg-primary rounded-circle p-1 h4'/>  
                                            </a>
                                            : null }  
                                    {
                                       (valor.Estado_inv !== "C")
                                       ?  <a href="#!" id={`${index}`}  onClick={(obj: any)=>{
                                                Elimina(index);
                                          }} >
                                            <FaTimesCircle title="Eliminar" color={'white'}   className='bg-danger rounded-circle p-1 h4'/>
                                          </a>
                                        : null   
                                    }                                                                             
                                       
                                </div>
                    }
                    auxDta.push(newObj);
                }); 
                //********Pasa el objeto a arreglo para recorrido en table
                dt = [...auxDta];
                dispatch(SetDataTable(dt));
                setRecords(auxDta); 
                setCpRecords(auxDta);                     
            }else{
                setRecords([]); 
                setCpRecords([]); 
            }       
        }
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{
        
        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()};     
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));   

        ValidaBtnFiltrat();   
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnFiltrat();                  
    }

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  
            
            ValidaBtnFiltrat(); 
        }

        preFiltros();    

    }, []);     

    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Reconteo / Avance y Cierre de Reconteo
                </div>
                <div className="h4 mt-4 mb-4 ms-3">AVANCE Y CIERRE DE RECONTEO</div>
            </div> 
            <Container fluid>                
                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS RECONTEO</div></Accordion.Header>
                        <Accordion.Body>
                        <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column w-50">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2"
                                    onChange={hndlrPeriodo}
                                    value={`${formData.Anno}-${formData.Mes}`} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault}/></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>
                            </div>

                            <div className="text-end">
                                <Button type="button" className=" btn border border-0" 
                                    disabled={hbltBtnAbrir}  onClick={()=>hndlrBtnFiltrar()} style={{backgroundColor: "#00B5B8"}}
                                >
                                    Filtrar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>         

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">RECONTEOS</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                <label className="m-1">Filtro</label>
                                <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                            </div>                              
                            <DataTable
                                columns={columns}
                                data={records}
                                selectableRowsHighlight
                                fixedHeader
                                pagination
                                highlightOnHover
                                fixedHeaderScrollHeight="600px"
                                paginationComponentOptions={pagOptions}
                            />  
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
            </Container>
            { showModalProductos && <MsgModalAvanceYcierreReconteo
                Show={showModalProductos}
                Title="Productos del Reconteo"
                BtnOkName="Aceptar"
                BtnNokName=""
                data={filaData}
                HandlerClickOk={()=>setShowModalProductos(false)}
                HanlerdClickNok={()=>null}
                frmData={formData}
            />}
            { msgDlgShowGen && <MsgDialogEspecial
                    Show={msgDlgShowGen}
                    Title={`Consultas - Reconteo`}
                    Icon="!"
                    Message={ (varGenerica === 1) 
                               ? "¿Esta seguro que desea cerrar éste reconteo?"
                               : "¿Esta seguro que desea eliminar éste reconteo??"
                            }
                    BtnOkName={(varGenerica === 1) 
                                ? "Cerrar Reconteo"
                                : "Eliminar Reconteo"
                            }
                    BtnNokName="Cancelar"
                    HandlerClickOk={(varGenerica === 1) 
                                     ? btnSiCerraReconteo
                                     : btnEliminarReconteo
                                   }
                    HanlerdClickNok={()=>setMsgDlgShowGen(false)}
                    body={body}
            /> }   
            {/*********** cuadro de dialogo para msj de api **********/}
            {msgDlgShowApiResponse && <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Consultas Reconteo`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={()=>setMsgDlgShowApiResponse(false)}
                HanlerdClickNok={null}
            />}                      
            {sHCarga && <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento porfavor, procesando..."
                color="#FF7588"
            />  }            
        </Container>
    );
}

export default AvanceCierreReconteoPage;