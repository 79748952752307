import { useEffect, useState } from "react";
import { Accordion, Button, Container} from "react-bootstrap";
import { FaCalendarAlt, FaFileArchive, FaFileDownload, FaFileExcel, FaFileUpload, FaTruck } from "react-icons/fa";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import { httpApiDownloadFile, httpApiGetText, httpApiPostText } from "../../../lib";
import * as env from '../../../env';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import ModalCargaDocumento from "./ModalCargaDocumento";
import MsgDialog from "../../../components/MsgDialog";
 

const DocumentosPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true);
    const [hbltBtnSubir, setHbltBtnSubir] = useState(true);
    const [showPreguntaBorrar, setShowPreguntaBorrar] = useState(false);    
    const [showCargaDocs, setShowCargaDocs] = useState(false);    
    const [sHCarga, setSHCarga] = useState(false);
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    const [msgDlgShowApiResponseBorrar, setMsgDlgShowApiResponseBorrar] = useState(false);    
    const [msgRespuestInv, setMsgRespuestInv] = useState("");  
    const [records, setRecords] = useState([]); 
    const [cpRecords, setCpRecords] = useState([]); 
    const [deleteFile, setDeleteFile] = useState("");   
    let [colorClasificacion, setColorClasificacion] = useState("");
    let [formData, setFormData] = useState({ Pais: "CL", Anno:  "", Mes:  "",  Centro:  "", Almacen: ""});

    const dispatch = useDispatch();                  
    
    let columns = [
        { name: "Nombre", selector: (row:any) => row.FILENAME,  width:"300px", sortable: true},
        { name: "Fecha", selector: (row:any) => row.FECHA_UPLOAD, sortable: true, width:"300px", wrap:true},
        { name: "Usuario", selector: (row:any) => row.USUARIO, sortable: true, width:"300px", wrap:true},
        { name: "Clasificación", selector: (row:any) => row.CLASIFICACION, sortable: true, width:"300px", wrap:true},
        { name: "Acciones", selector: (row:any) => row.Acciones,  width:"300px"}
    ];

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  


    const getColor = (clf: string) =>{

        const cadArr = colorClasificacion.split(",");
        const clr = cadArr.filter((dt: string)=> dt.includes(clf));
        const color = clr[0].split("#");
        return (`#${color[1]}`);

    }

    const conditionalRowStyles = [    
        {
            when : () => true,
            style: (row: any) => ({ backgroundColor: getColor(row.CLASIFICACION) }),
        }
    ];
    
    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.FILENAME && dato.FILENAME.includes(evnt.target.value.toUpperCase()))
                 || (dato.FECHA_UPLOAD && dato.FECHA_UPLOAD.includes(evnt.target.value.toUpperCase()))  
                 || (dato.USUARIO && dato.USUARIO.includes(evnt.target.value.toUpperCase()));                             
            });
            setRecords(reg); 
        }
    } 
    
    const ValidaBtnAbrir = () => {
        if ((formData.Anno !== "") &&
            (formData.Almacen !== "") && 
            (formData.Centro !== "")){
                setHbltBtnAbrir(false);
                if (emp.permisoSubirDocumentos){
                    setHbltBtnSubir(false);
                }else{
                    setHbltBtnSubir(true);                    
                }   
        }else{
            setHbltBtnAbrir(true);            
        }
    }

    const download = (file: any) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)
      
        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
      
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }

    const descarga = (data: any, fileName: string) =>{

        const reader = new FileReader();        

        reader.onload = (evt: any) => {

            const fl = new File([evt.target.result], fileName, {type: "application/octet-stream"});
            download(fl);
        };      

        reader.readAsArrayBuffer(data);

    }

    const descargaArchivo = async (nmFile: string, todos: boolean) => {

        setSHCarga(true);

        const recurso = `${env.REACT_APP_API_URL}/api/Archivos?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&NombreArchivo=${todos ? "" : nmFile}`;      
        const response = await httpApiDownloadFile(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const data: any = (response.message); 

        descarga(data, nmFile);

        if (response.code >= 400){
            setMsgRespuestInv("Se presentó un problema al intentar descargar el archivo.")
            setMsgDlgShowApiResponse(true);
        }else{
            setMsgRespuestInv("El archivo se ha descargado con éxito.")
            setMsgDlgShowApiResponse(true);          
        }

        setSHCarga(false);        
        
    }

    const borraArchivo = async () => { 

        setShowPreguntaBorrar(false);
        setSHCarga(true);

        const recurso = `${env.REACT_APP_API_URL}/api/Archivos?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}&NombreArchivo=${deleteFile}`;      
        const response = await httpApiPostText(recurso, "Delete", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, {});

        let msg = "";
        if (response.code >= 400){        
            const data: any = JSON.parse(response.message);
            msg = data.Message;
        }else{
            msg = response.message;
        }
        setSHCarga(false); 
        setMsgRespuestInv(msg);
        setMsgDlgShowApiResponseBorrar(true);
    }    
    
    const preguntaBorraArchivo = () => { 

        setShowPreguntaBorrar(true);

    }   
       
    const hndlrBtnBuscar = async () =>{
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Archivos?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);    
     
        if (response.code >= 400){
            setMsgRespuestInv(arrDta);
            setMsgDlgShowApiResponse(true);
        }
        else{      
            const auxDta:any = [];
            arrDta.map((obj: any, index: number)=>{
                const newObj = {
                    ...obj, 
                    Acciones: <div className="d-flex flex-row gap-3"> 
                                <div>                                                                    
                                    <a href="#!" id={`${index}`}  onClick={()=>{
                                            descargaArchivo(obj.FILENAME, false);
                                        }} >
                                        <FaFileDownload title="Descargar archivo" color={'white'}   className='bg-success rounded-circle p-1 h4'/>
                                    </a> 
                                </div>
                                {
                                    (emp.permisoBorrarDocumentos) 
                                    ? <div>                                                                    
                                        <a href="#!" id={`${obj.FILENAME}`}   
                                            onClick={()=>{
                                                setDeleteFile(obj.FILENAME);
                                                preguntaBorraArchivo();
                                            }} >
                                            <FaFileExcel title="Elimina archivo" color={'white'}   className='bg-danger rounded-circle p-1 h4'/>
                                        </a> 
                                      </div> 
                                    : null  
                                }
                                  
                             </div>
                };
                auxDta.push(newObj);
            });            
            setRecords(auxDta);    
            setCpRecords(auxDta);                    
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));         

        sessionStorage.setItem("entorno", JSON.stringify(emp));   

        formData = {...formData, Anno: yy, Mes: mm};
        setFormData({...formData});
        ValidaBtnAbrir();                      
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{  
        
        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()};     
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));       

        ValidaBtnAbrir();    
    }

    const hndlrOpcionAlmacen = (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData({...formData}); 

        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  

        ValidaBtnAbrir();                  
    }
    
    const MsgDlghandlerResponseApi = () =>{
        setMsgDlgShowApiResponse(false);
    } 

    const MsgDlghandlerResponseApiDelete = () =>{
        setMsgDlgShowApiResponseBorrar(false);
        hndlrBtnBuscar();
    }     

    const onModalCargaDocumentoClose = () =>{
        
        setShowCargaDocs(false);
        hndlrBtnBuscar();

    } 

    useEffect(()=>{

        const preFiltros = () =>{
          
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }     
            
            ValidaBtnAbrir();
        }

        const asociaColorDocumentos = () =>{

            const clasi: string = "" + env.REACT_APP_COLOR_CLASIFICACION;
            const docs: string = "" + env.REACT_APP_COLOR_DOCUMENTOS;

            const colores = docs.split(",");
            clasi.split(",").map((dt: string, idx: number)=>{
                colorClasificacion += dt + colores[idx] + ",";
            }); 
            setColorClasificacion(colorClasificacion);
   
        }

        colorClasificacion = "";
        preFiltros();  
        asociaColorDocumentos(); 

    }, []);    
   
    return(
        <>
            <Container fluid>
            <BarraMenuHz/>  
                <div >
                    <div className="text-primary ms-3 mt-3">
                            Home / Inventario / Documentos
                    </div>
                    <div className="h4 mt-4 mb-4 ms-3">INVENTARIO DE DOCUMENTOS</div>
                </div>  
                <Container fluid >
                    <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark">PARÁMETROS DOCUMENTOS</div></Accordion.Header>
                            <Accordion.Body>
                                <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                                <div className="align-middle">
                                    <FaCalendarAlt className="h5" /> 
                                    <label className="ms-2 h4 ">Periodo</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" />
                                <div  className="d-flex flex-column col-6">
                                    <label className="m-2 ">Mes/Año</label>
                                    <input type="month" id="periodo" size={8}  className="ms-2"
                                        value={`${formData.Anno}-${formData.Mes}`}
                                        onChange={hndlrPeriodo} 
                                        min="1990-01" max="9999-12"
                                    />
                                </div>
                                <div className="align-middle mt-4">
                                    <FaTruck className="h5" /> 
                                    <label className="ms-2 h4 ">Centro y Almacen</label>
                                </div>
                                <hr className="t-0 m-0 mb-3" /> 

                                <div className="row d-flex flex-row mb-3">
                                    <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Centro de distribución</label>    
                                        <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault}/></div>
                                    </Container>
                                    <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                        <label className="form-label">Seleccionar Almacén </label>    
                                        <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro} OnSeleccion={hndlrOpcionAlmacen}  default={emp.almacenDefault} /></div>
                                    </Container>
                                </div>

                                <div className="text-end">
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                        disabled={hbltBtnAbrir} onClick={() => hndlrBtnBuscar()}
                                    >
                                        Filtrar
                                    </Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>         

                    <Accordion className="mt-3" defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0" className="border shadow ">
                            <Accordion.Header><div className=" h5 text-dark"></div></Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex flex-row mb-3 gap-3">
                                    <div className="text-end">
                                        <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                            disabled={hbltBtnSubir} 
                                            onClick={()=> setShowCargaDocs(true)}
                                        >
                                            <div className="d-flex flex-row gap-2">
                                                <label htmlFor=""><FaFileUpload /></label>  
                                                <label htmlFor="">Subir archivo</label>                                                  
                                            </div>
                                        </Button>                                        
                                    </div>     
                                    <div className="text-end">
                                        <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                            onClick={()=>descargaArchivo(`${formData.Anno}-${formData.Mes}-${formData.Centro}-${formData.Mes}.zip`, true)} disabled={hbltBtnAbrir} 
                                        >
                                            <div className="d-flex flex-row gap-2">
                                                <label htmlFor=""><FaFileArchive /></label>  
                                                <label htmlFor="">Descargar todos</label>                                                  
                                            </div>
                                        </Button>                                        
                                    </div>                                     
                                                            
                                </div>
                                <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                    <label className="m-1">Filtro</label>
                                    <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                </div>                                                                
                                <DataTable
                                        columns={columns}
                                        data={records}
                                        selectableRowsHighlight
                                        fixedHeader
                                        pagination
                                        highlightOnHover
                                        fixedHeaderScrollHeight="600px"
                                        paginationComponentOptions={pagOptions}
                                        conditionalRowStyles={conditionalRowStyles}
                                    />  
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> 
                </Container> 
            </Container>
            {/******* Carga masiva *******************************/}
            <ModalCargaDocumento 
                Data={cpRecords}
                Show={showCargaDocs}
                Title="Carga documento"
                BtnOkName="Cargar documento"
                BtnNokName="Cerrar"
                HandlerClickOk={null}
                HanlerdClickNok={onModalCargaDocumentoClose}
                formData={formData}
                User={emp.user}
            />                                       
            {/*********** cuadro de dialogo para msj de api **********/}
            <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Inventario - Documentos`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApi}
                HanlerdClickNok={null}
            />   

            <MsgModalDialogEspecial
                Show={msgDlgShowApiResponseBorrar}
                Title={`Inventario - Documentos`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApiDelete}
                HanlerdClickNok={null}
            />   
            
            {/*********** cuadro de dialogo para preguntar si elimina o no el archivo **********/}
            <MsgDialog
                Show={showPreguntaBorrar}
                Title='Carga de Documento'
                BtnOkName='Si, continuar'
                HandlerClickOk={borraArchivo}
                Message="¿Está usted seguro de querer borrar el documento?"
                Icon='x'
                BtnNokName='No, cancelar'
                HanlerdClickNok={()=>{
                    setShowPreguntaBorrar(false);
                }}
            />                         
            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento porfavor, procesando..."
                color="#FF7588"
            />               
        </>
    );
}

export default DocumentosPage;
