
import { useEffect, useState } from 'react';
//import Button from 'react-bootstrap/Button';
import DataTable from 'react-data-table-component';
import LocalMsgModalSpinner from '../../../../components/LocalMsgModalSpinner';
import * as env from '../../../../env';
import { httpApiGetText } from '../../../../lib';
//import { exportToExcel } from '../../../../util';


const DetalleProducto = (props: {Codigo:any, formData: any}) =>{

  let [records, setRecords] = useState([]);
  const [sHCarga, setSHCarga] = useState(false);  

  let columns = [
    { name: "Cantidad", selector: (row:any) => row.Cantidad, format: (row:any) => row.Cantidad.toLocaleString(), right: true, sortable: true, width:"100px"},
    { name: "Serial", selector: (row:any) => row.Serial, sortable: true, width:"100px"},
    { name: "Esp", selector: (row:any) => row.Especial, sortable: true,  width:"70px"},
    { name: "Nro. Doc.", selector: (row:any) => row.NroDocto,  sortable: true,  width:"100px"},
    { name: "Pos. Doc.", selector: (row:any) => row.PosDocto, sortable: true,  width:"100px"}, 
    { name: "Fecha", selector: (row:any) => row.Fecha.substring(0, 10), sortable: true,  width:"120px"},
    { name: "Usuario", selector: (row:any) => row.Usuario, sortable: true,  width:"100px"},
    { name: "Observación", selector: (row:any) => row.Observacion, sortable: true,   width:"120px", wrap: true},        
  ]

  const pagOptions = {
      rowsPerPageText: "Filas por páginas",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos"
  }; 

  useEffect(()=>{

    const llamadaApi = async () =>{
      setSHCarga(true);
      const recurso = `${env.REACT_APP_API_URL}/api/Reconteos?pais=CL&Anno=${props.formData.Anno}&Mes=${props.formData.Mes}` + 
                      `&Centro=${props.formData.Centro}&Almacen=${props.formData.Almacen}&Producto=${props.Codigo}&Diferencias=0&Especial=`;  
                      
      const response = await httpApiGetText(recurso, {
        'Content-Type': 'application/json',
        'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
        'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
    });

      const arrDta = await JSON.parse(response.message);   
      console.log("llamada api: ", arrDta);
      const auxDta:any = [];
      arrDta.Movimientos.map((obj: any, index: number)=>{
        const newObj = {
          Cantidad: obj.Cantidad, 
          Serial : obj.Serial, 
          Especial : ((obj.Especial)? obj.Especial: ""),
          NroDocto: obj.Nro_Documento,
          PosDocto: obj.PosDocto,
          Fecha: obj.Fecha,
          Usuario: obj.Usuario,
          Observacion: obj.Observacion
        };
        auxDta.push(newObj);
      });
      setRecords(auxDta);
      setSHCarga(false);
    };

    llamadaApi();

    return ()=> {
        records = [];
    }

  }, [props.Codigo]);

  return(
        <div className='mt-3 d-flex justify-content-center' style={{backgroundColor: "#eeeceb"}} >
          <div>
              {/* <Button className="btn btn-success" onClick={()=>{ 
                      if (records.length > 0 )
                        exportToExcel("tablaAvanceDetalle", records)
                      }
              }>Exportar</Button> */}
              <div className='border m-3 rounded '>
                  <DataTable
                      columns={columns}
                      data={records}
                      selectableRowsHighlight
                      fixedHeader
                      pagination
                      highlightOnHover
                      fixedHeaderScrollHeight="400px"
                      paginationComponentOptions={pagOptions}
                  />                      
              </div>
          </div>
          {sHCarga && <LocalMsgModalSpinner 
              Show={sHCarga}
              text="Un momento por favor, verificando detalle..."
              color="#FF7588"
          />}            
        </div>
  )

}

export default DetalleProducto;
