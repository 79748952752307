import { useEffect, useState } from "react";
import { Accordion, Button, Container, Form} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaCalendarAlt, FaFileDownload, FaRegTrashAlt} from "react-icons/fa";
import { useSelector } from "react-redux";
import BarraMenuHz from "../../../components/BarraMenoHz";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText} from "../../../lib";
import ModalDiaHora from "../../../components/ModalDiaHora";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import { JSX } from "react/jsx-runtime";
import MsgDialog from "../../../components/MsgDialog";

//*********Configuración de la Grilla */
let ColCntsAlmc = [
    { name: "Centro", selector: (row:any) => row.centro, width:"130px"},
    { name: "Almacén", selector: (row:any) => row.almacen,  width:"140px"},       
]

let columns = [
    { name: "Centro", selector: (row:any) => row.CENTRO, sortable: true, width:"120px"},
    { name: "Almacén", selector: (row:any) => row.ALMACEN, sortable: true, width:"130px"},
    { name: "Fecha/Hora", selector: (row:any) => row.FECHA_PROG, width:"170px", sortable: true},        
    { name: "Usuario", selector: (row:any) => row.USUARIO, width:"120px", sortable: true},
    { name: "Est. Stock", selector: (row:any) => row.ESTADO_STOCK, width:"130px", sortable: true, wrap:true},
    { name: "Est. Costo", selector: (row:any) => row.ESTADO_COSTO, width:"130px", sortable: true, wrap:true},     
    { name: " ", selector: (row:any) => row.ACCION, sortable: true, width:"60px"},       
];

const pagOptions = {
    rowsPerPageText: "Filas x Pag.",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos"
};  

const customStyles = {
    headRow: {
      style: {
        backgroundColor: '#F0F0F0', // Green background
        fontSize: '14px', // Larger font size
      },
    },
};

const HoraCorte = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    let [arrChks, setArrChks] = useState(new Array());
    let [sector, setSector] = useState("");
    let [periodo, setPeriodo] = useState("");
    const [data, setData] = useState(new Array());
    let [data2, setData2] = useState(new Array());
    const [modalDiaHora, setModalDiaHora] = useState(false);
    const [msgDlgMessage, setMsgDlgMessage] = useState("");
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);    
    const [msgDlgShowYesNo, setMsgDlgShowYesNo] = useState(false);  
    const [itemDelete, setItemDelete] = useState({ ANNO_IN: "", MES_IN: "", CENTRO: "", ALMACEN: "", FECHA_PROG: "", USUARIO: "", ESTADO_STOCK: "", ESTADO_COSTO: ""});      

    const hndlrCHK = (arg: any) =>{

        setArrChks([...arg.selectedRows]);
    }

    const buscarPeriodo = async (periodo: string) =>{
        
        const yy = periodo.split('-')[0];
        const mm = periodo.split('-')[1];

        setPeriodo(`${yy}-${mm}`);

        const recurso = `${env.REACT_APP_API_URL}/api/HoraCorte?pais=CL&anno=${yy}&mes=${mm}`;     
        const response = await httpApiGetText(recurso, {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        const arrDta = await JSON.parse(response.message);
        if (response.code >= 400){
            setMsgDlgMessage("Se presentó un problema al intentar realizar la operación.");
            setMsgDlgShowApiResponse(true);
        }else{
            let aux: { CENTRO: any; ALMACEN: any; FECHA_PROG: any; USUARIO: any; ESTADO_STOCK: any; ESTADO_COSTO: any; ACCION: JSX.Element; }[] = [];
            arrDta.map((dt: any)=>{
                const nobj = {
                    CENTRO: dt.CENTRO,
                    ALMACEN: dt.ALMACEN,
                    FECHA_PROG: dt.FECHA_PROG,
                    USUARIO: dt.USUARIO,
                    ESTADO_STOCK: dt.ESTADO_STOCK,
                    ESTADO_COSTO: dt.ESTADO_COSTO,
                    ACCION:  <div className="d-flex flex-row ">                           
                                    <FaRegTrashAlt title="Eliminar" 
                                        color={'red'}
                                        className='h4' 
                                        onClick={()=>{
                                            setItemDelete(dt);
                                            setMsgDlgShowYesNo(true);
                                        }}
                                    />                       
                            </div>
                };
                aux.push(nobj);
            });
            data2 = [...aux];
            setData2(data2);
        }
    }

    const hndlerPeriodo = async(evnt: any) =>{

        buscarPeriodo(evnt.target.value);

    }    

    const hndlerModalOk= async(dyh: any) =>{

        setModalDiaHora(false);

        const arrSend: { ANNO_IN: any; MES_IN: any; CENTRO: any; ALMACEN: any; FECHA_PROG: string; USUARIO: any; ESTADO_STOCK: string; ESTADO_COSTO: string; }[] = [];
        arrChks.map((cnts: any)=>{
            const bdy = {
                ANNO_IN: dyh.fecha.split("-")[0],
                MES_IN: dyh.fecha.split("-")[1],
                CENTRO: cnts.centro,
                ALMACEN: cnts.almacen,
                FECHA_PROG: `${dyh.fecha} ${dyh.hora}`,
                USUARIO: emp.user,
                ESTADO_STOCK: "A",
                ESTADO_COSTO: "A"
            };
            arrSend.push(bdy);
        });

        const recurso = `${env.REACT_APP_API_URL}/api/HoraCorte`;     
        const response = await httpApiPostText(recurso, "PUT", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, arrSend);
        const arrDta = await JSON.parse(response.message);
        if (response.code >= 400){
            setMsgDlgMessage("Se presentó un problema al intentar realizar la operación.");
            setMsgDlgShowApiResponse(true);
        }else{
            setMsgDlgMessage(arrDta)
            setMsgDlgShowApiResponse(true);          
        }

        buscarPeriodo(periodo);
    }   
    
    const hndlerEliminar = async() =>{

        setMsgDlgShowYesNo(false);

        const bdy = {
            ANNO_IN: periodo.split("-")[0],
            MES_IN: periodo.split("-")[1],
            CENTRO: itemDelete.CENTRO,
            ALMACEN: itemDelete.ALMACEN,
        };

        const recurso = `${env.REACT_APP_API_URL}/api/HoraCorte`;     
        const response = await httpApiPostText(recurso, "DELETE", {
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        }, bdy);
        const arrDta = await JSON.parse(response.message);
        if (response.code >= 400){
            setMsgDlgMessage("Se presentó un problema al intentar borrar el registro.")
            setMsgDlgShowApiResponse(true);
        }else{
            setMsgDlgMessage(arrDta)
            setMsgDlgShowApiResponse(true);          
        }

        buscarPeriodo(periodo);

    }    
    
    useEffect(()=>{  
        const init = () =>{
            emp.almacenes.map((dato: string)=>{
                const obj = {
                    centro: dato.split(" - ")[0],
                    almacen: dato.split(" - ")[1],
                };
                data.push(obj);
            });
            setData([...data]);
        };

        init();

    }, []);   

    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Mantenedor / Hora Corte
                </div>
                <div className="h4 mt-4 mb-4 ms-3">HORA CORTE</div>
            </div> 
            <Container fluid>
                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">Período correspondiente a mes de inventario</div></Accordion.Header>
                        <Accordion.Body>
                            <div  className="row mb-3">
                                <div className=" col-lg-3 align-middle p-2">
                                    <FaCalendarAlt className="h5" /> 
                                    <label className="ms-2 h5 ">Sector</label>
                                    <div>
                                        <Form.Select  value={sector} aria-label="Default select example" onChange={(e: any) => console.log(e)}  id="Sector" disabled={true}>
                                                <option key={-1} value={""}>Todos</option>
                                                {/*                                             {
                                                    filtro.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                                }     */}                                      
                                        </Form.Select>
                                    </div>
                                </div>

                                <div  className="d-flex flex-column col-lg-2 p-2 ">
                                        <label className="m-2 h5 ">Año/mes</label>
                                        <input type="month" id="fechaInicial" size={8}  className="ms-2 mb-1 "
                                            onChange={hndlerPeriodo}
                                            value={periodo}
                                        />                                    
                                </div> 
                            </div>
                            <div className="row  b-2">
                                <div className="col-lg-3 p-2"> 
                                    <div className="table-responsive">
                                            <DataTable
                                                columns={ColCntsAlmc}
                                                data={data}                
                                                selectableRowsHighlight
                                                fixedHeader
                                                pagination={false}
                                                highlightOnHover
                                                selectableRows
                                                customStyles={customStyles}
                                                fixedHeaderScrollHeight="400px"
                                                paginationComponentOptions={pagOptions}
                                                onSelectedRowsChange={hndlrCHK}
                                                className="border rounded"
                                            />                                    
                                    </div>                                    
                                </div>
                                <div  className="d-flex flex-column col-lg-1  p-2">
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} 
                                        className="border-0" 
                                        onClick={()=>setModalDiaHora(true)}
                                        disabled={arrChks.length < 1}
                                    >
                                        <label htmlFor=""> &gt;&gt;&gt; </label>
                                    </Button>                                
                                </div> 
                                <div className="col-lg-8 p-2"> 
                                    <div className="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={data2}                
                                                selectableRowsHighlight
                                                fixedHeader
                                                pagination={false}
                                                highlightOnHover
                                                customStyles={customStyles}
                                                fixedHeaderScrollHeight="400px"
                                                paginationComponentOptions={pagOptions}
                                                onSelectedRowsChange={hndlrCHK}
                                                className="border rounded"
                                            />                                    
                                    </div>                                    
                                </div>                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>         
            </Container>
            {/*********** cuadro de dialogo para msj de api **********/}
            {modalDiaHora && <ModalDiaHora
                Show={modalDiaHora}
                Title={`Selección de día y hora`}
                Icon="x"
                Message={""}
                BtnOkName="Aceptar"
                BtnNokName="Cancelar"
                HandlerClickOk={hndlerModalOk}
                HanlerdClickNok={()=>setModalDiaHora(false)}
            />} 

            {msgDlgShowApiResponse &&  <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Hora corte`}
                Icon="x"
                Message={msgDlgMessage}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={()=>setMsgDlgShowApiResponse(false)}
                HanlerdClickNok={null}
            /> }      

            {msgDlgShowYesNo &&  <MsgDialog
                Show={msgDlgShowYesNo}
                Title={`Hora corte`}
                Icon="x"
                Message={"¿Está usted seguro de eliminar el registro?"}
                BtnOkName="Sí, continuar"
                BtnNokName="No, cancelar"
                HandlerClickOk={hndlerEliminar}
                HanlerdClickNok={()=>setMsgDlgShowYesNo(false)}
            /> }                      
        </Container>
    );
}

export default HoraCorte;
