import { useEffect, useState } from "react";
import { Accordion, Button, Container} from "react-bootstrap";
import DataTable from "react-data-table-component";

import { useSelector } from "react-redux";
import BarraMenuHz from "../../../components/BarraMenoHz";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import MsgModalDialogEspecial from "../../../components/MsgModalDialogEspecial";
import * as env from '../../../env';
import { httpApiGetText} from "../../../lib";
import { exportToExcel } from "../../../util";
import Multiselect from "multiselect-react-dropdown";

const cnts: {}[] = [] ;

const KardexInicialPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);
    const [records, setRecords] = useState([]);
    const [cpRecords, setCpRecords] = useState([]);    
    let [centros, setCentros] = useState(cnts);    
    const [almacenesFiltrados, setAlmacenesFiltrados] = useState([]);
    const [almacenesSelected, setAlmacenesSelected] = useState([]);    
    const [btnToExcel, setBtnToExcel] = useState(true);
    const [sHCarga, setSHCarga] = useState(false);
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true);      
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: ""}); 
    const [msgDlgShowApiResponse, setMsgDlgShowApiResponse] = useState(false);
    const [msgRespuestInv, setMsgRespuestInv] = useState("");

    let columns = [
        { name: "Centro", selector: (row:any) => row.CENTRO.toLocaleString(), sortable: true, width:"100px"},
        { name: "Almacén", selector: (row:any) => row.ALMACEN.toLocaleString(), sortable: true, width:"100px"},
        { name: "SKU Código", selector: (row:any) => row.INV_ITEM_ID.toLocaleString(), width:"120px", sortable: true},
        { name: "Especial", selector: (row:any) => row.ESPECIAL, width:"90px", sortable: true},
        { name: "Descripción", selector: (row:any) => row.DESCRIPCION, width:"280px", sortable: true, wrap:true},
        { name: "Cantidad SAP", selector: (row:any) => row.CANTIDAD_SAP,  format: (row:any) => row.CANTIDAD_SAP.toLocaleString(), right:true, sortable: true, width:"150px"}, 
        { name: "Valor por Unidad", selector: (row:any) => row.VALOR_X_UNIDAD, format: (row:any) => row.VALOR_X_UNIDAD.toLocaleString(), sortable: true, right:true, width:"150px"},
        { name: "Stock Valorizado", selector: (row:any) => row.STOCK_VALORIZADO, format: (row:any) => row.STOCK_VALORIZADO.toLocaleString() , sortable: true, right:true, width:"150px"},
        { name: "Clasif. 1", selector: (row:any) => row.CLASIFICACION1,  format: (row:any) => row.CLASIFICACION1.toLocaleString(), sortable: true, width:"150px"}, 
        { name: "Clasif. 2", selector: (row:any) => row.CLASIFICACION2, format: (row:any) => row.CLASIFICACION2.toLocaleString(), sortable: true, width:"150px"},
        { name: "Clasif. 3", selector: (row:any) => row.CLASIFICACION, format: (row:any) => row.CLASIFICACION.toLocaleString() , sortable: true, width:"150px"}              
    ]

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  

    const changeTextFiltro = (evnt: any) => {
        if (evnt.target.value.trim() === ""){
            setRecords(cpRecords);  
        }else{
            let reg = cpRecords.filter((dato: any)=> {
                return (dato.CENTRO && dato.CENTRO.includes(evnt.target.value.toUpperCase()))
                    || (dato.ALMACEN && dato.ALMACEN.includes(evnt.target.value.toUpperCase())) 
                    || (dato.INV_ITEM_ID && dato.INV_ITEM_ID.toString().includes(evnt.target.value.toUpperCase()))  
                    || (dato.ESPECIAL && dato.ESPECIAL.includes(evnt.target.value.toUpperCase()))                     
                    || (dato.DESCRIPCION && dato.DESCRIPCION.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.CANTIDAD_SAP && dato.CANTIDAD_SAP.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.VALOR_X_UNIDAD && dato.VALOR_X_UNIDAD.toString().includes(evnt.target.value.toUpperCase())) 
                    || (dato.STOCK_VALORIZADO && dato.STOCK_VALORIZADO.toString().includes(evnt.target.value.toUpperCase()));                     
            }); 
            setRecords(reg); 
        }
    }  

    const hndlrBtnBuscar = async () =>{

        let dts: string = "";
        almacenesSelected.map((dato: any)=>{
            dts = `${dato.cat};${dato.key.split(' - ')[0].trim()}-${dts}`;
        });    
        setSHCarga(true);
        const recurso = `${env.REACT_APP_API_URL}/api/Kardex?Pais=CL&Anno=${formData.Anno}&Mes=${formData.Mes}&CentroAlmacen=${dts}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message);     
        const auxDta:any = [];
        if (response.code >= 400){
            setMsgRespuestInv(arrDta.Message);
            setMsgDlgShowApiResponse(true);
            setRecords(auxDta);              
        }
        else{
            if (arrDta.length > 0){  
                arrDta.map((valor: any, index: number)=>{
                    const newObj = {
                        PAIS:valor.PAIS,  
                        ANNO:valor.ANNO, 
                        MES:valor.MES,                       
                        CENTRO:valor.CENTRO,
                        ALMACEN:valor.ALMACEN,
                        INV_ITEM_ID:valor.INV_ITEM_ID,
                        ESPECIAL:valor.ESPECIAL,
                        DESCRIPCION:valor.DESCRIPCION,
                        CANTIDAD_SAP:valor.CANTIDAD_SAP,
                        VALOR_X_UNIDAD:valor.VALOR_X_UNIDAD,
                        STOCK_VALORIZADO:valor.STOCK_VALORIZADO,
                        CLASIFICACION1:valor.CLASIFICACION1,
                        CLASIFICACION2:valor.CLASIFICACION2,
                        CLASIFICACION:valor.CLASIFICACION,                        
                    }
                    auxDta.push(newObj);
                }); 
                //********Pasa el objeto a arreglo para recorrido en table
                setRecords(auxDta); 
                setCpRecords(auxDta);
                setBtnToExcel(false);
            }else{
                setBtnToExcel(true);
            }       
        }
    }

    const MsgDlghandlerResponseApi = () =>{
        setMsgDlgShowApiResponse(false);
    }  

    const exportAExcell = (name: string, data: any) =>{

        exportToExcel(name, data)
    }

    const habilitaBuscar = (lng: number) => {
        
        setHbltBtnAbrir(!lng);

    }

    const onSelectedOptionCenter = (e: any) =>{
         
        if (e.length > 0){
            const almacenes: any = [];
            e.map((dato: any)=>{
                const alma = emp.almacenes.filter( (alm: string)=> alm.includes(dato.cat) );
                //organiza la estructura de almacens así: {cat: id, key: label} paramostrar en el multiselect
                alma.map((dt: string) => {
                    const cnt = dato.cat;
                    const almcn = `${dt.split(' - ')[1]} - ${dt.split(' - ')[2]}`; 
                    almacenes.push({cat : cnt, key : almcn});
                });
            });

            setAlmacenesFiltrados(almacenes);    
        }else{
            setAlmacenesFiltrados([]);            
        }

    }

    const onRemoveOptionCenter = (e: any, centRmv: any) =>{
        
        const almcremv = almacenesSelected.filter((dato: any) => dato.cat !== centRmv.cat);
        setAlmacenesSelected(almcremv);
        if (almcremv.length < 1){
            setAlmacenesFiltrados([]);
        }

        if (e.length > 0){
            const almacenes: any = [];
            e.map((dato: any)=>{
                const alma = emp.almacenes.filter( (alm: string)=> alm.includes(dato.cat) );
                //organiza la estructura de almacens así: {cat: id, key: label} paramostrar en el multiselect
                alma.map((dt: string) => {
                    const cnt = dato.cat;
                    const almcn = `${dt.split(' - ')[1]} - ${dt.split(' - ')[2]}`; 
                    almacenes.push({cat : cnt, key : almcn});
                });
            });

            setAlmacenesFiltrados(almacenes);    
        }else{
            setAlmacenesFiltrados([]);            
        }

        habilitaBuscar(almcremv.length);
    }
    
    const onSelectedOptionAlmacen = (e: any) =>{

        setAlmacenesSelected(e);
        habilitaBuscar(e.length);

    }

    const onRemoveOptionAlmacen = (e: any) =>{
        
        habilitaBuscar(e.length);
    }
    
    useEffect(()=>{

        const preFiltros = async () =>{

            formData = { ...formData, Anno: `${new Date().getFullYear()}` ,  Mes:  `${new Date().getMonth() + 1}` };
            setFormData(formData);

            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            } 

            const centross: any = [];
            await emp.centros.map((dt: string) => {
                const cnt = dt.split(' - ')[0];
                const nmCnt = `${cnt} - ${dt.split(' - ')[1]}`; 
                centross.push({cat : cnt, key : nmCnt});
            });  
            setCentros(centross);            
        }

        preFiltros();  


    }, []);

    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Auditoria / Kardex Inicial
                </div>
                <div className="h4 mt-4 mb-4 ms-3">KARDEX INICIAL</div>
            </div> 
            <Container fluid>
                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS KARDEX</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 

                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">* Seleccionar Centro de distribución</label>    
                                    <div>
                                        <Multiselect className="" aria-label="Default select example" 
                                            onSelect={(e: any)=>onSelectedOptionCenter(e)}
                                            onRemove={(e: any, remcnt: any)=>onRemoveOptionCenter(e, remcnt)}
                                            options={centros}
                                            displayValue="key"
                                            placeholder="Centros de distribución"
                                        />
                                    </div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">* Seleccionar Almacen </label>    
                                    <div>
                                        <Multiselect className="" aria-label="Default select example" 
                                            onSelect={(e: any)=>onSelectedOptionAlmacen(e)}  
                                            onRemove={onRemoveOptionAlmacen}                                         
                                            options={almacenesFiltrados}
                                            displayValue="key"
                                            placeholder="Almacen(es)"
                                            selectedValues={almacenesSelected}
                                        />
                                    </div>
                                </Container>
                            </div>

                            <div className="text-end">
                                <Button type="button" className=" btn border border-0" 
                                    disabled={hbltBtnAbrir}  onClick={hndlrBtnBuscar} style={{backgroundColor: "#00B5B8"}}
                                >
                                    Buscar
                                </Button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>         

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">KARDEX</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="text-start mb-4">
                                    <div className="d-flex flex-column col col-lg-6 mb-3 ">
                                        <label className="m-1">Filtro</label>
                                        <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                    </div>  
                                    <div className="m-1 col-md-12 col-lg-8 " > 
                                        <Button type="button" className="border-0" style={{backgroundColor:"#16D39A"}}
                                            disabled={btnToExcel}
                                            onClick={async ()=> exportAExcell("KardexInicial", records)}>
                                            Exportar
                                        </Button>
                                    </div>                                     
                            </div>
                            <DataTable
                                columns={columns}
                                data={records}
                                fixedHeader
                                pagination
                                highlightOnHover
                                fixedHeaderScrollHeight="600px"
                                paginationComponentOptions={pagOptions}                          
                            />    
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  
            </Container>
            {/*********** cuadro de dialogo para msj de api **********/}
            <MsgModalDialogEspecial
                Show={msgDlgShowApiResponse}
                Title={`Kardex Inicial`}
                Icon="x"
                Message={msgRespuestInv}
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={MsgDlghandlerResponseApi}
                HanlerdClickNok={null}
            /> 

            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />              
        </Container>
    );
}

export default KardexInicialPage;
